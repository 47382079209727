import React from "react";

const WhitePaper = () => {
  return (
    <>
      <section className="whitpe-paper">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="whitpe-paper-img shadow-box">
                <img
                  src="/assets/img/Frame.png"
                  className="img-fluid"
                  alt="true"
                />
              </div>
            </div>
            <div className="col-md-6 justify-content-center d-flex  align-items-center">
              <div className="white-paper-modal">
                <div className="bg-heading-paper">
                  <h2>
                    {" "}
                    <span> White Paper </span>
                  </h2>
                </div>
                <div className="Paper-btn">
                  <a
                    className="down"
                    href={`assets/img/WhitePaper.pdf`}
                    download="assets/img/WhitePaper.pdf"
                  >
                    {" "}
                    <img
                      src="/assets/img/download.png"
                      className="img-fluid"
                      alt=""
                    />{" "}
                    <span>Download</span>
                  </a>
                  <a href={`assets/img/WhitePaper.pdf`} target="_blank">
                    {" "}
                    <img
                      src="/assets/img/eye.png"
                      className="eye"
                      alt=""
                    />{" "}
                    <span>View</span>
                  </a>
                </div>
                <div className="content-section">
                  <h6>
                    Insights That Matter: Access Our Thought-Provoking White
                    Papers
                  </h6>
                  <p>
                    This will assist you in fast becoming familiar with all the
                    mechanics and in better understanding your objectives for
                    our product.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhitePaper;
