import React from "react";
import Header1 from "../constants/Header1";
import Header2 from "../constants/Header2";
import Footer from "../constants/Footer";

const PublicLayout = (props) => {
  return (
    <React.Fragment>
      <Header1 />
      <Header2 />
      {props.children}
      <Footer />
    </React.Fragment>
  );
};

export default PublicLayout;
