import React, { useState, useEffect } from "react";
import { injectModels } from "../../Redux/injectModels";
import toast from "react-hot-toast";
import FormData from "form-data";
import { CircularProgress } from "@mui/material";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

const EditProfile = (props) => {
  const walletAddress = props.location && props.location.state.walletAddress;
  const coverImageTypes = {
    mimeTypes: [
      "image/jpeg",
      "image/x-citrix-jpeg",
      "image/pjpeg",
      "image/png",
      "image/x-citrix-png",
      "image/x-png",
    ],
    extensions: [".jpeg", ".jpg", ".png"],
  };
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [image, setImage] = useState("");
  const [imageUpdated, setImageUpdated] = useState("");
  const [isUserProfileUpdated, setIsUserProfileUpdated] = useState("");
  const [errorFirstName, setErrorFirstName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const avatarFileRef = React.useRef();
  const history = useHistory();

  const handleFirstName = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const MAX_WORDS = 2;
    const words = val.trim().split(/\s+/);
    if (words.length > MAX_WORDS) {
      setErrorFirstName(`First name must be ${MAX_WORDS} words only or fewer`);
    } else if (val.trim() === "") {
      setErrorFirstName("First Name is required");
    } else {
      setErrorFirstName("");
    }
    setFirstName(val.trimStart());
  };
  const handleLastName = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const MAX_WORDS = 1;
    const words = val.trim().split(/\s+/);
    if (words.length > MAX_WORDS) {
      setErrorLastName(`Last name must be ${MAX_WORDS} words or fewer`);
    } else if (val.trim() === "") {
      setErrorLastName("Last Name is required");
    } else {
      setErrorLastName("");
    }
    setLastName(val.trimStart());
  };

  async function handleImageChange(e) {
    const file = e.target.files[0];
    if (!file) {
      toast.success("Please select image");
      return;
    }
    if (!file.type.startsWith("image/")) {
      toast.error("Please select a valid image file");
      return;
    }
    const data = new FormData();
    data.append("fileInput", file);
    try {
      setIsUploading(true);
      const response = await props.admin.uploadImage(data);
      if (response.data.file) {
        setImage(response.data && response.data.file.url);
        setImageUpdated(response.data && response.data.file.url);
        setIsUploading(false);
        props.application.setLoading(false);
      } else {
        props.application.setLoading(false);
      }
    } catch (error) {
      toast.error(error.toString());
      props.application.setLoading(false);
    }
  }

  const getData = async () => {
    props.application.setLoading(true);

    try {
      const res = await props.admin.getUserProfileDetails(walletAddress);
      if (res.data) {
        const data = res.data.data.user;
        setFirstName(data.firstName.trim());
        setLastName(data.lastName.trim());
        setImage(data.avatar);
        setIsUserProfileUpdated(data.isProfileUpdated && data.isProfileUpdated);
        props.application.setLoading(false);
      } else {
        props.application.setLoading(false);
      }
    } catch (error) {
      props.application.setLoading(false);
    }
  };
  const updateProfile = async (e) => {
    e.preventDefault();
    const MAX_WORDS = 2;
    const words = firstName.trim().split(/\s+/);
    if (words.length > MAX_WORDS) {
      setErrorFirstName(`First name must be ${MAX_WORDS} words only or fewer`);
      return;
    } else if (firstName.trim() === "") {
      setErrorFirstName("First Name is required");
      return;
    } else {
      setErrorFirstName("");
    }
    try {
      const data = {
        fname: firstName && firstName,
        lname: lastName ? lastName : "",
        avatar: image && image,
        walletAddress: walletAddress && walletAddress,
      };
      const res = await props.admin.updateUserDetails(data);
      props.application.setLoading(true);
      if (res.data.status === 201) {
        toast.dismiss();
        toast.success(res.data.message);
        history.push("/dashboard");
        getData();
        props.application.setLoading(false);
      } else {
        toast.error("Something went wrong!");
        props.application.setLoading(false);
      }
    } catch (error) {
      console.log(error);
      props.application.setLoading(false);
      toast.error("Could not update details");
    }
  };

  const handleRemoveImage = () => {
    Swal.fire({
      title: "Confirmation",
      text: "Are you sure you want to delete image",
      showCancelButton: true,
      confirmButtonColor: "#12b6b3",
      cancelButtonColor: "#373737",

      confirmButtonText: `Yes`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          props.application.setLoading(true);

          setImage(
            "https://api.nakatoken.io/images/8efd7c6199a0e0cba4428e774778f377.1707123104916.png"
          );
          props.application.setLoading(false);
          toast.success("Successfully delete your images");
        } catch (err) {
          props.application.setLoading(false);
          console.log(err);
        }
      }
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <section
        className="user-profile"
        style={{
          backgroundImage: "url(/assets/img/profile-banner-svg.png)",
        }}
      ></section>
      <section className="user-detail">
        <div className="user-detail-rtfy">
          <div className="edit-btn-profile-tryts">
            <div className="edit-profile">
              {isUploading ? (
                <CircularProgress style={{ color: "#12b6b3" }} />
              ) : (
                <img src={image} className="img-radius" alt="" />
              )}
              <input
                hidden
                ref={avatarFileRef}
                type="file"
                name="profileImage"
                onChange={handleImageChange}
                accept={coverImageTypes.mimeTypes.join(", ")}
              />
              <div
                className="box_content asset-uploader mb-3"
                onClick={(e) => {
                  e.preventDefault();
                  avatarFileRef.current.click();
                }}
              >
                <button>
                  {" "}
                  <i className="fa-regular fa-pen-to-square" />
                </button>
              </div>

              <div
                className="box_content_trash asset-uploader mb-3"
                onClick={handleRemoveImage}
              >
                <button className="delete-icon">
                  {" "}
                  <i className="fa-solid fa-trash" />
                </button>
              </div>
            </div>
          </div>
          <div className="user-list-name">
            <div className="user-editprofile">
              <form onSubmit={updateProfile}>
                <div className="thrgy">
                  <div className="profile-user">
                    <div className="mb-2">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        First Name*
                      </label>
                      <input
                        type="Name"
                        className="form-control is-invalid"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        autoComplete="off"
                        placeholder="First Name"
                        value={firstName}
                        onChange={handleFirstName}
                      />
                      {errorFirstName && (
                        <div className="invalid-feedback">{errorFirstName}</div>
                      )}
                    </div>
                  </div>
                  <div className="profile-user">
                    <div className="mb-2">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label"
                      >
                        Last Name
                      </label>
                      <input
                        type="Name"
                        className="form-control is-invalid"
                        id="exampleInputPassword1"
                        aria-describedby="emailHelp"
                        autoComplete="off"
                        placeholder="Last Name"
                        value={lastName}
                        onChange={handleLastName}
                      />
                      {errorLastName && (
                        <div className="invalid-feedback">{errorLastName}</div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="editprofile-submit">
                  <button type="submit"> Update</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default injectModels(["admin", "application"])(EditProfile);
