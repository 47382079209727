import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useAccount } from "wagmi";
import Lottie from "lottie-react";
import withdrawAnimation from "../../animation/withdraw_animation.json";
import * as CONTRACTS from "../../Contract";
import { injectModels } from "../../Redux/injectModels";
import Web3 from "web3";
import toast from "react-hot-toast";

const Investments = (props) => {
  const [showInvestmentModal, setShowInvestmentModal] = useState(false);
  const [investmentToken, setInvestmentToken] = useState("");
  const [investmentDetails, setInvestmentDetails] = useState([]);

  const handleOpenModal = () => setShowInvestmentModal(true);
  const handleCloseModal = () => setShowInvestmentModal(false);
  const handleInputChange = (e) => setInvestmentToken(e.target.value);

  const ADDRESS = process.env.REACT_APP_INVESTOR_CONTRACT_ADDRESS;
  const BLOCKCHAIN_NETWORK = process.env.REACT_APP_BLOCKCHAIN_NETWORK;
  const { address, isConnecting, isDisconnected } = useAccount({
    onConnect({ address, connector, isReconnected }) {},
    onDisconnect() {
      window.location.reload();
    },
  });

  const addInvestment = async () => {
    const provider = window.ethereum
      ? window.ethereum
      : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);

    const web3 = new Web3(provider);
    try {
      const contract = new web3.eth.Contract(
        CONTRACTS.NakaInvestor.abi,
        ADDRESS
      );
      const tx = contract.methods.addInvestment(investmentToken);
      props.application.setLoading(true);
      const gas = await tx.estimateGas({ from: address });
      const gasPrice2 = await web3.eth.getGasPrice();
      const receipt = await tx.send({
        from: address,
        gas: gas,
        gasPrice: gasPrice2 * 2,
      });
      props.application.setLoading(false);
      toast.success("Investment added successfully");
      handleCloseModal();
    } catch (error) {
      props.application.setLoading(false);
      toast.error(error.message);
    }
  };

  const getInvestment = async () => {
    try {
      props.application.setLoading(true);
      if (address) {
        const provider = window.ethereum
          ? window.ethereum
          : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);
        const web3 = new Web3(provider);
        const contract = new web3.eth.Contract(
          CONTRACTS.NakaInvestor.abi,
          ADDRESS
        );
        const receipt = await contract.methods.getInvestments(address).call();
        console.log(receipt);
        setInvestmentDetails(receipt.userInvestments_);
        props.application.setLoading(false);
      } else {
        console.log("No address found");
        props.application.setLoading(false);
      }
    } catch (err) {
      console.log(err, "err");
      props.application.setLoading(false);
    }
  };

  useEffect(() => {
    getInvestment();
  }, []);

  console.log(investmentDetails, "details");

  return (
    <>
      <section className="total-earning">
        <div className="user-full-details">
          <div className="investment">
            <div className="total-investment">
              <div className="tbing-transaction">
                <h6>Total Investments</h6>
              </div>
            </div>

            <div className="add-investment">
              <div className="claimtoken">
                <button onClick={handleOpenModal}>Add Investments</button>
              </div>
            </div>
          </div>
          {/* <div>
            {investmentDetails.length > 0 ? (
              investmentDetails.map((investment, index) => (
                <div key={index} className="sdtyge">
                  <section className="individual-investment-section">
                    <div className="investdata-card">
                      <h6>
                        Investment ID: <span>{investment.id_}</span>
                      </h6>
                      <h6>
                        Amount: <span>{investment.amount_} USDT</span>
                      </h6>
                      <h6>
                        Start Date:{" "}
                        <span>
                          {new Date(
                            investment.startDate_ * 1000
                          ).toLocaleString()}
                        </span>
                      </h6>
                      <h6>
                        Maturity Date:{" "}
                        <span>
                          {new Date(
                            investment.maturityDate_ * 1000
                          ).toLocaleString()}
                        </span>
                      </h6>
                      <button disabled>Release</button>
                    </div>
                  </section>
                </div>
              ))
            ) : (
              <p>No investments found</p>
            )}
          </div> */}

          <div className="sdtyge-wrapper">
            {investmentDetails.length > 0 ? (
              investmentDetails.map((investment, index) => (
                <div key={index} className="sdtyge">
                  <section className="individual-investment-section">
                    <div className="investdata-card">
                      <h6>
                        Investment ID: <span>{investment.id_}</span>
                      </h6>
                      <h6>
                        Amount: <span>{investment.amount_} USDT</span>
                      </h6>
                      <h6>
                        Start Date:{" "}
                        <span>
                          {new Date(
                            investment.startDate_ * 1000
                          ).toLocaleString()}
                        </span>
                      </h6>
                      <h6>
                        Maturity Date:{" "}
                        <span>
                          {new Date(
                            investment.maturityDate_ * 1000
                          ).toLocaleString()}
                        </span>
                      </h6>
                      {/* <button>Release</button> */}
                    </div>
                  </section>
                </div>
              ))
            ) : (
              <p>No investments found</p>
            )}
          </div>
        </div>
      </section>

      <Modal show={showInvestmentModal} onHide={handleCloseModal}>
        <div className="withdraw nktmodal">
          <Modal.Header closeButton>
            <Modal.Title>Add Investment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Lottie
              style={{ height: 150, margin: 0 }}
              animationData={withdrawAnimation}
              loop={true}
            />
            <div className="wallet-balance">
              <div className="usdt-balance">
                <form>
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Please enter the token"
                    value={investmentToken}
                    onChange={handleInputChange}
                  />
                </form>
                <button className="modalwithdraw" onClick={addInvestment}>
                  Submit
                </button>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default injectModels(["admin", "application"])(Investments);
