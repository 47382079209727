
import React from 'react';
import { Route } from 'react-router-dom';
import { PublicLayout } from '../layouts';

const UnAunthenticRoute = ({ component: Component, ...rest }) => {
    const joined = localStorage.getItem("isjoined")

    return (
        <Route {...rest} render={(props) => {
            return (
                <React.Fragment>
                   <PublicLayout><Component {...props} /></PublicLayout>
                </React.Fragment>
            )
        }} />
    );
};

export default UnAunthenticRoute;