import React, { useState, useEffect } from 'react'
import { injectModels } from "../../Redux/injectModels";
import toast from "react-hot-toast";
import parse from 'html-react-parser';

const TermsCondition = (props) => {

  const [data, setData] = useState({
    title: '',
    subTitle: '',
    description: '',
  });

  useEffect(() => {
    window.scrollTo(0,0);
    getTermsConditionData();
  }, []);

  const getTermsConditionData = async () => {
    try {
      props.application.setLoading(true);
      const response = await props.admin.getTermsConditions();
      if (response.termCondition) {
        setData(response.termCondition);
        props.application.setLoading(false);
      } else {
        toast.error(response.message)
        props.application.setLoading(false);
      }
    } catch (error) {
      console.error(error);
      props.application.setLoading(false);

    }
  };
  return (
    <section className="terms-condtion">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="terms-content">
              <h2>{parse(data.title)}</h2>
              <h6>{parse(data.subTitle)}</h6>
               <p>{parse(data.description)}</p>
               </div>
            </div>   
          </div>      
        </div>
      </section>

  )
}

export default injectModels(['admin','application'])(TermsCondition)