import React, { useState, useEffect } from "react";
import { useAccount } from "wagmi";
import * as CONTRACTS from "../../Contract";
import { injectModels } from "../../Redux/injectModels";
import Web3 from "web3";
import RenderElement from "./RenderElement";
import BuisnessDetail from "./BuisnessDetail";

const Network = (props) => {
  const ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;
  const BLOCKCHAIN_NETWORK = process.env.REACT_APP_BLOCKCHAIN_NETWORK;
  const { address } = useAccount({
    onConnect({ address, connector, isReconnected }) {},
    onDisconnect() {
      window.location.reload();
    },
  });

  const [openElementList, setOpenElementList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [fetchingStatus, setFetchingStatus] = useState(false);

  let requestCounter = 0;
  const getUsersList = async (address) => {
    requestCounter++;
    try {
      // props.application.setLoading(true);
      const provider = window.ethereum
        ? window.ethereum
        : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);

      const web3 = new Web3(provider);
      const contract = new web3.eth.Contract(CONTRACTS.Nakamoto.abi, ADDRESS);
      const receipt = await contract.methods
        .getUser(address)
        .call();

      setUsersList((pre) => [...pre, receipt]);

      if (receipt.sponsors.length > 0) {
        for (let index = 0; index < receipt.sponsors.length; index++) {
          await getUsersList(receipt.sponsors[index]);
        }
      }
      // props.application.setLoading(false);
    } catch (err) {
      console.log(err);
    } finally {
      requestCounter--;
      if (requestCounter === 0) {
        setFetchingStatus(true);
      }
    }
  };

  useEffect(() => {
    getUsersList(address);
  }, []);

  return (
    <>
      <section className="network">
        <div className="container-fluid">
          <div className="user-count">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="home-tab-pane"
                  aria-selected="false"
                >
                  Network
                </button>
              </li>
              {fetchingStatus ? (
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="profile-tab-pane"
                    aria-selected="false"
                  >
                    Buisness Details
                  </button>
                </li>
              ) : (
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    role="tab"
                    aria-controls="profile-tab-pane"
                    aria-selected="false"
                  >
                    Buisness Details Fetching... 
                  </button>
                </li>
              )}
            </ul>
            <div>Total User Count : {usersList.length}</div>
          </div>
          <div className="tab-content accordion" id="myTabContent">
            <div
              className="tab-pane fade show active accordion-item"
              id="home-tab-pane"
              role="tabpanel"
              aria-labelledby="home-tab"
              tabIndex={0}
            >
              <div id="chart-container"></div>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show  d-lg-block"
                aria-labelledby="headingOne"
                data-bs-parent="#myTabContent"
              >
                <div
                  className="container-fluid"
                  style={{
                    overflowX: "auto",
                    whiteSpace: "nowrap",
                    paddingBottom: "100px",
                    paddingTop: "20px",
                  }}
                >
                  <RenderElement
                    userAddress={address}
                    openElementList={openElementList}
                    setOpenElementList={setOpenElementList}
                    parentAddress={address}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            className="tab-pane fade accordion-item"
            id="profile-tab-pane"
            role="tabpanel"
            aria-labelledby="profile-tab"
            tabIndex={0}
          >
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#myTabContent"
            >
              <BuisnessDetail data={usersList} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default injectModels(["admin", "application"])(Network);
