import React, { useState, useEffect } from "react";
import { injectModels } from "../../Redux/injectModels";
import toast from "react-hot-toast";

const FAQ = (props) => {
  const [faq, setFaq] = useState([]);

  useEffect(() => {
    getFaqs();
    window.scrollTo(0, 0);
  }, []);

  const getFaqs = async () => {
    try {
      props.application.setLoading(true);
      const response = await props.admin.getFaq();
      if (response.Faqs) {
        setFaq(response.Faqs.reverse());
        props.application.setLoading(false);
      } else {
        toast.error(response.message);
        props.application.setLoading(false);
      }
    } catch (error) {
      console.error(error);
      props.application.setLoading(false);
    }
  };
  return (
    <>
      <section className="faq faqpage">
        <div className="container">
          <div className="bg-heading-faq">
            <h2>
              {" "}
              <span> FAQs </span>
            </h2>
          </div>
          <div className="row">
            <div className="accordion" id="accordionExample">
              {faq.map((faqitem, index) => (
                <div className="accordion-item" key={index}>
                  <h2 className="accordion-header" id={`heading${index}`}>
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${index}`}
                      aria-expanded="false"
                      aria-controls={`collapse${index}`}
                    >
                      {faqitem.question}
                    </button>
                  </h2>
                  <div
                    id={`collapse${index}`}
                    className="accordion-collapse collapse  "
                    aria-labelledby={`heading${index}`}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <strong>{faqitem.answer}</strong>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default injectModels(["admin", "application"])(FAQ);
