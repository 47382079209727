const { ethers } = require("ethers");
const {
  abi: IUniswapV3PoolABI,
} = require("@uniswap/v3-core/artifacts/contracts/interfaces/IUniswapV3Pool.sol/IUniswapV3Pool.json");
const {
  abi: QuoterABI,
} = require("@uniswap/v3-periphery/artifacts/contracts/lens/Quoter.sol/Quoter.json");

const { getAbi, getPoolImmutables } = require("./helpers");

export async function getPrice(
  INPUT_AMOUNT,
  RPC_URL,
  BLOCKSCAN_API_KEY,
  POOL_ADDRESS,
  QUOTER_ADDRESS
) {
  const provider = new ethers.providers.JsonRpcProvider(RPC_URL);

  const poolContract = new ethers.Contract(
    POOL_ADDRESS,
    IUniswapV3PoolABI,
    provider
  );

  const tokenAddress0 = await poolContract.token0();
  const tokenAddress1 = await poolContract.token1();

  const tokenAbi0 = await getAbi(tokenAddress0, BLOCKSCAN_API_KEY);
  const tokenAbi1 = await getAbi(tokenAddress1, BLOCKSCAN_API_KEY);

  const tokenContract0 = new ethers.Contract(
    tokenAddress0,
    tokenAbi0,
    provider
  );
  const tokenContract1 = new ethers.Contract(
    tokenAddress1,
    tokenAbi1,
    provider
  );

  const tokenSymbol0 = await tokenContract0.symbol();
  const tokenDecimals0 = await tokenContract0.decimals();

  const quoterContract = new ethers.Contract(
    QUOTER_ADDRESS,
    QuoterABI,
    provider
  );

  const immutables = await getPoolImmutables(poolContract);

  const amountIn = ethers.utils.parseUnits(
    INPUT_AMOUNT.toString(),
    tokenDecimals0
  );

  const quotedAmountOut = await quoterContract.callStatic.quoteExactInputSingle(
    immutables.token0,
    immutables.token1,
    immutables.fee,
    amountIn,
    0
  );

  const amountOut = ethers.utils.formatUnits(quotedAmountOut, 6);

  return amountOut;
}
