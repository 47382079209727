import React from "react";

const NakaCoin = () => {
  return (
    <>
      <section className="naka-coin">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="whitpe-paper-img shadow-box">
                <div className="naka-moto-img">
                  <img
                    src="/assets/img/NakaMoto.png"
                    className="img-fluid nkt-coin"
                    alt="true"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 justify-content-center d-flex  align-items-center">
              <div className="white-paper-modal">
                <div className="bg-heading-paper">
                  <h2>
                    {" "}
                    <span> Naka Token</span>
                  </h2>
                </div>

                <div className="content-section">
                  <h6>
                    Unlocking Potential with Naka Token (NKT): Your Gateway to
                    Decentralized Finance
                  </h6>
                  <p>
                    Welcome to the world of decentralized finance powered by
                    Naka Token (NKT). NKT is an ERC20 standard token built on
                    the polygon blockchain, designed to revolutionize the way
                    you engage with digital assets. As the symbol of innovation
                    and empowerment, Naka Token opens doors to a plethora of
                    opportunities in the ever-evolving landscape of
                    decentralized finance (DeFi).
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NakaCoin;
