import axios from "axios";
import * as CONSTANT from "./constants";

//API integration for all request and responce for Upload Image
export const uploadImage = (data) => async (dispatch, getState) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/upload/upload`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response && response.status === 200 && response.data) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};
//End

//API integration for all request and responce for Notification
export const getUserNotification = () => async (dispatch, getState) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/notification/get-user-notification`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response && response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};
//End

//API integration for all request and responce for Notification
export const getUserNotificationDetail =
  (address) => async (dispatch, getState) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/user/get-user-detail?address=${address}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response && response.status === 200 && response.data) {
        dispatch({
          type: CONSTANT.FETCH_NOTIFICATION_COUNT,
          payload: response.data,
        });
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  };
//End

//API integration for all request and responce for Notification
export const UserNotificationUpdates =
  (address, id) => async (dispatch, getState) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/user/update-notification?walletAddress=${address}&_id=${id}`,
        {
          headers: {
            // "Content-Type": "application/json",
            // "Authorization": `Bearer ${access_token}`
          },
        }
      );
      if (response && response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  };
//End

//API integration for all request and responce for Faq
export const getFaq = () => async (dispatch, getState) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/faqs/get-faqs-user`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};
//End

//API integration for all request and responce for Contact us
export const addContactUs = (data) => async (dispatch, getState) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/contact/contact-us`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response && response.status === 200 && response.data) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};
//End

//API integration for all request and responce for About us
export const getAboutUs = () => async (dispatch, getState) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/page/about-us`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};
//End

//API integration for all request and responce for Terms & Conditions
export const getTermsConditions = () => async (dispatch, getState) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/page/term-condition`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};
//End

//API integration for all request and responce for Privacy Policy
export const getPrivacyPolicy = () => async (dispatch, getState) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/page/privacy-policy`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};
//End

export const updateTotalJoin = (totalJoin) => ({
  type: "UPDATE_TOTAL_JOIN",
  payload: totalJoin,
});

export const updateProfitGenerated = (profitGenerated) => ({
  type: "UPDATE_PROFIT_GENERATED",
  payload: profitGenerated,
});

//API integration for all request and responce for update user details
export const updateUserDetails = (data) => async (dispatch, getState) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/user/update-user-details`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response && response.status === 200 && response.data) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};
//End

//API integration for all request and responce for update user details
export const getUserProfileDetails =
  (address) => async (dispatch, getState) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/user/get-user-detail?address=${address}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response && response.status === 200 && response.data) {
        return response;
      }
    } catch (error) {
      console.log(error);
    }
  };
//End
export const addUserDetails = (data) => async (dispatch, getState) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/user/create-user`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response && response.status === 200 && response.data) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};
export const getAllUsers = () => async (dispatch, getState) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/user/get-user`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response && response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getReferalWalletAddress =
  (referalId) => async (dispatch, getState) => {
    debugger;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/user/get-upline-address?uplineId=${referalId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response, "action response");
      if (response && response.status === 200 && response.data) {
        return response.data.address;
      } else {
        console.log("something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };
//End
