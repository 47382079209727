import React from "react";

const DashboardFooter = () => {
  const currentYear = new Date().getFullYear();

  return (
    <section className="das-footer">
      <div className="das-footer-text">
        <p>All Rights Reserved. © Naka Token {currentYear}</p>
      </div>
    </section>
  );
};

export default DashboardFooter;
