import React from "react";

const Channels = () => {
  return (
    <>
      <section className="offical-channels shadow-box">
        <div className="bg-heading-channels">
          <h2>
            <span> Official Channels </span>
          </h2>
          <p>Let’s chat and see how we can help?</p>
        </div>
        <div className="container">
          <div className="row justify-content-center ">
            <div className="col-md-4 col-sm-6 col-8">
              <a href="https://telegram.me/Naka_Token" target="_blank">
                <div className="telegram">
                  <div className="telegram-icon">
                    <div className="tel-img">
                      {" "}
                      <img
                        src="/assets/img/telegram.png"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>

                  <div className="telegram-content">
                    <h6>Telegram</h6>
                    <p>@Naka_Token</p>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-4 col-sm-6 col-8">
              <a href="https://www.instagram.com/naka_token/" target="_blank">
                <div className="telegram">
                  <div className="telegram-icon">
                    <div className="tel-img">
                      {" "}
                      <img
                        src="/assets/img/instagram.png"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>

                  <div className="telegram-content">
                    <h6>Instagram</h6>
                    <p>@Naka_Token</p>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-4 col-sm-6 col-8">
              <a href="https://x.com/naka_token" target="_blank">
                <div className="telegram">
                  <div className="telegram-icon">
                    <div className="tel-img">
                      {" "}
                      <img
                        src="/assets/img/twitter.png"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>

                  <div className="telegram-content">
                    <h6>X</h6>
                    <p>@Naka_Token</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Channels;
