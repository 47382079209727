import React, { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import * as CONTRACTS from "../../Contract";
import Web3 from "web3";
import { injectModels } from "../../Redux/injectModels";
import { ethers } from "ethers";
import toast from "react-hot-toast";
import { Modal } from "react-bootstrap";
import { useContractWrite } from "wagmi";
import { getPrice } from "../../components/uinswap_v3_price/uinswapbot";
import Lottie from "lottie-react";
import loadingAnimation from "../../animation/loading.json";
import withdrawAnimation from "../../animation/withdraw_animation.json";
import { green } from "@mui/material/colors";

const Wallet = (props) => {
  const [spotBalance, setSpotBalance] = useState("");
  const [isNktBalance, setNktBalance] = useState("");
  const [timestamp, setTimeStamp] = useState("");
  const [spotNonWorkingIncome, setSpotNonWorkingIncome] = useState("");
  const [nktNonWorkingIncome, setNktNonWorkingIncome] = useState("");
  const [todayNonWorkingIncome, setTodayNonWorkingIncome] = useState("");
  const [todaySponsorIncome, setTodaySponsorIncome] = useState("");
  const [todayTeamBuildBonus, setTodayTeamBuildBonus] = useState("");
  const [todayBoostIncome, setTodayBoostIncome] = useState("");
  const [todayRoyaltyIncome, setTodayRoyaltyIncome] = useState("");
  const [spotSponsorIncome, setSpotSponsorIncome] = useState("");
  const [nktSponsorIncome, setNktSponsorIncome] = useState("");
  const [spotTeamBuildBonus, setSpotTeamBuildBonus] = useState("");
  const [nktTeamBuildBonus, setNktTeamBuildBonus] = useState("");
  const [spotBoostIncome, setSpotBoostIncome] = useState("");
  const [nktBoostIncome, setNktBoostIncome] = useState("");
  const [spotRoyaltyIncome, setSpotRoyaltyIncome] = useState("");
  const [nktRoyaltyIncome, setNktRoyaltyIncome] = useState("");
  const [web3Instance, setweb3Instance] = useState();
  const [tokenAmount, setTokenAmount] = useState();
  const [transactions, setTransactions] = useState();
  const [phase, setPhase] = useState("");
  const [showTopUpModal, setShowTopUpModal] = useState(false);
  const [showTopUpModal2, setShowTopUpModal2] = useState(false);
  const [showTopUpModal3, setShowTopUpModal3] = useState(false);
  const [showTopUpModal4, setShowTopUpModal4] = useState(false);
  const [avialbleSpotBalance, setAvialableSpotBalance] = useState(0);
  const [avialbleNakaBalance, setAvialableNakaBalance] = useState(0);
  const [isApplicable, setIsApplicable] = useState(false);
  const [lastWithdrawalTimestamp, setLastWithdrawalTimestamp] = useState("");
  const [timeDifference, setTimeDifference] = useState(null);
  const [currentFunctionIndex, setCurrentFunctionIndex] = useState(0);
  const [isHold, setIsHold] = useState("");
  const [usersDetails, setUsersDetails] = useState([]);
  const [packages, setPackages] = useState([]);
  const [swapAvialable, setSwapAvialable] = useState("");
  const [swapValue, setSwapValue] = useState("");
  const [nKTValuePrice, setNKTValuePrice] = useState(null);
  const [isActive, setIsActive] = useState("");
  const [loading, setLoading] = useState(false);
  const [isAvialableForWithdraw, setIsAvailableForWithdraw] = useState(false);
  const [NKTValuePriceInNUmber, setNKTValuePriceInNUmber] = useState("");
  const [avialableNKTToken, setAvialableNKTToken] = useState("");
  const [withdrawalAmount, setWithdrawalAmount] = useState("");
  const [error, setError] = useState("");
  const [withdrawalDetails, setWithdrawalDetails] = useState("");
  const [usersList, setUsersList] = useState([]);
  const [fetchingStatus, setFetchingStatus] = useState(false);
  const [totalBuisnessWithTeam, setTotalBuisnessWithTeam] = useState("");
  const [otherLegBuisness, setOtherLegBuisness] = useState("");
  const [superLegBuisness, setSuperLegBuisness] = useState("");

  const nonWorkingIncome =
    Number(spotNonWorkingIncome) + Number(nktNonWorkingIncome);
  const sponsorIncome = Number(spotSponsorIncome) + Number(nktSponsorIncome);
  const teamBuildBonus = Number(spotTeamBuildBonus) + Number(nktTeamBuildBonus);
  const boostIncome = Number(spotBoostIncome) + Number(nktBoostIncome);
  const royaltyIncome = Number(spotRoyaltyIncome) + Number(nktRoyaltyIncome);

  const todaysTotalIncome =
    Number(todayNonWorkingIncome) +
    Number(todaySponsorIncome) +
    Number(todayTeamBuildBonus) +
    Number(todayBoostIncome) +
    Number(todayRoyaltyIncome);
  const currTime = new Date().toLocaleTimeString();

  const ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;
  const BLOCKCHAIN_NETWORK = process.env.REACT_APP_BLOCKCHAIN_NETWORK;
  const { address, isConnecting, isDisconnected } = useAccount({
    onConnect({ address, connector, isReconnected }) {},
    onDisconnect() {
      window.location.reload();
    },
  });

  const getTransactions = () => {
    setShowTopUpModal(true);
  };

  const handleCloseTopUpModal = () => {
    setShowTopUpModal(false);
  };

  const handleCloseTopUpModal2 = () => {
    setShowTopUpModal2(false);
  };

  const handleCloseTopUpModal3 = () => {
    setShowTopUpModal3(false);
  };

  const handleCloseTopUpModal4 = () => {
    setShowTopUpModal4(false);
  };
  const { write } = useContractWrite({
    onError(error) {
      switch (currentFunctionIndex) {
        case 1:
          props.application.setLoading(false);
          toast.dismiss();
          toast.error(error.details);
          console.log("Error", error);
        case 2:
          setCurrentFunctionIndex(0);
          toast.error(`An error happened:${error.details}`);
          props.application.setLoading(false);
          console.log(error);
          break;
        case 3:
          setCurrentFunctionIndex(0);
          toast.error(`An error happened:${error.details}`);
          props.application.setLoading(false);
          console.log(error);
          break;
        default:
          setCurrentFunctionIndex(0);
      }
    },

    onSettled(data, error) {
      switch (currentFunctionIndex) {
        case 1:
          setCurrentFunctionIndex(0);
          if (error) {
            props.application.setLoading(false);
            toast.dismiss();
            toast.error(`Error: ${error.error.message}`);
            setCurrentFunctionIndex(0);
          } else {
            props.application.setLoading(false);
            toast.dismiss();
            toast.success("Withdrawal successfully to wallet!");
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
          break;
        case 2:
          setCurrentFunctionIndex(0);
          if (error) {
            toast.error(error && error.details, "error");
          } else {
            props.application.setLoading(false);
            toast.dismiss();
            toast.success("Swap Old NaKa Token successfully!");
          }
          break;

        case 3:
          setCurrentFunctionIndex(0);
          props.application.setLoading(false);
          toast.dismiss();
          toast.success("Swap Old NaKa Token successfully!");
        default:
          setCurrentFunctionIndex(0);
          if (error) {
          } else {
          }
      }
    },
  });

  const getUsersData = async () => {
    try {
      props.application.setLoading(true);
      const provider = window.ethereum
        ? window.ethereum
        : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);

      const web3 = new Web3(provider);
      setweb3Instance(web3);
      const contract = new web3.eth.Contract(CONTRACTS.Nakamoto.abi, ADDRESS);
      const receipt = await contract.methods.getUserStatus(address).call();
      const userDetails = await contract.methods.getUser(address).call();
      if (userDetails.swappedTokenIncome > 0) {
        setSwapAvialable(true);
        setSwapValue(userDetails.swappedTokenIncome);
      }
      // try {
      //   const usersDetail = await contract.methods.getUsers().call();
      //   setUsersDetails(usersDetail);
      // } catch (err) {
      //   props.application.setLoading(false);
      //   console.log(err, "Error");
      // }

      const packages = await contract.methods
        .getPackagesWithJoiningDetails(address)
        .call();

      const userAnalytics = await contract.methods
        .getUserAnalytics(address)
        .call();
      setPackages(packages.packages_);
      setTodayNonWorkingIncome(
        userAnalytics &&
          web3?.utils.fromWei(userAnalytics.todayNonWorkingIncome, "ether")
      );
      setTodaySponsorIncome(
        userAnalytics &&
          web3?.utils.fromWei(userAnalytics.todaySponsorIncome, "ether")
      );
      setTodayTeamBuildBonus(
        userAnalytics &&
          web3?.utils.fromWei(userAnalytics.todayTeamBuildBonus, "ether")
      );
      setTodayBoostIncome(
        userAnalytics &&
          web3?.utils.fromWei(userAnalytics.todayBoostIncome, "ether")
      );
      setTodayRoyaltyIncome(
        userAnalytics &&
          web3?.utils.fromWei(userAnalytics.todayRoyaltyIncome, "ether")
      );

      setPhase(userDetails.joinedPackages[0].phase);

      setIsHold(receipt && receipt.isUserOnHold_);

      setSpotBalance(
        userDetails && web3?.utils.fromWei(userDetails[12], "ether")
      );
      setNktBalance(
        userDetails && web3?.utils.fromWei(userDetails[13], "ether")
      );
      setSpotNonWorkingIncome(
        userDetails && web3?.utils.fromWei(userDetails[24], "ether")
      );
      setNktNonWorkingIncome(
        userDetails && web3?.utils.fromWei(userDetails[25], "ether")
      );
      setSpotSponsorIncome(
        userDetails && web3?.utils.fromWei(userDetails[22], "ether")
      );
      setNktSponsorIncome(
        userDetails && web3?.utils.fromWei(userDetails[23], "ether")
      );

      setSpotTeamBuildBonus(
        userDetails && web3?.utils.fromWei(userDetails[20], "ether")
      );
      setNktTeamBuildBonus(
        userDetails && web3?.utils.fromWei(userDetails[21], "ether")
      );
      setSpotBoostIncome(
        userDetails && web3?.utils.fromWei(userDetails[18], "ether")
      );
      setNktBoostIncome(
        userDetails && web3?.utils.fromWei(userDetails[19], "ether")
      );
      setSpotRoyaltyIncome(
        userDetails && web3?.utils.fromWei(userDetails[16], "ether")
      );
      setNktRoyaltyIncome(
        userDetails && web3?.utils.fromWei(userDetails[17], "ether")
      );

      setAvialableNakaBalance(
        userDetails &&
          web3?.utils.fromWei(userDetails.availableNakaWalletIncome, "ether")
      );
      setTimeStamp(userDetails && userDetails.joiningTimestamp);
      setAvialableSpotBalance(
        userDetails &&
          web3?.utils.fromWei(userDetails.availableSpotWalletIncome, "ether")
      );
      setTransactions(userDetails && userDetails.withdraws);
      const lastWithdrawalTimestamp = getLastWithdrawalTimestamp(
        userDetails.withdraws
      );
      setLastWithdrawalTimestamp(lastWithdrawalTimestamp);

      if (lastWithdrawalTimestamp) {
        const currentTime = new Date().getTime();
        const lastWithdrawalTime = new Date(
          lastWithdrawalTimestamp * 1000
        ).getTime();
        const differenceInSeconds = Math.floor(
          (currentTime - lastWithdrawalTime) / 1000
        );
        setTimeDifference(convertTimestampToDateTime(lastWithdrawalTimestamp));
      }
      props.application.setLoading(false);
    } catch (err) {
      props.application.setLoading(false);
      console.log("Error ", err);
    }
  };

  const handleChange = (e) => {
    const numericValue = e.target.value.replace(/[^0-9.]/g, "");
    if (numericValue === "") {
      setWithdrawalAmount("");
    } else {
      const sanitizedValue = parseInt(numericValue, 10);

      if (sanitizedValue > 0) {
        setWithdrawalAmount(numericValue);
        setError("");
      } else {
        setError("Please enter a value greater than 0.");
      }
    }
  };

  const getLastWithdrawalTimestamp = (withdrawals) => {
    if (withdrawals && withdrawals.length > 0) {
      const lastWithdrawal = withdrawals[withdrawals.length - 1];
      return parseInt(lastWithdrawal.timestamp);
    }
    return null;
  };

  const handleSpotWithdrawlModal = async () => {
    const provider = window.ethereum
      ? window.ethereum
      : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);
    const web3 = new Web3(provider);

    if (NKTValuePriceInNUmber) {
      try {
        props.application.setLoading(true);
        const contract = new web3.eth.Contract(
          CONTRACTS.ERC20.abi,
          "0x5dd1cbf142F4B896D18aF835C1734363b0d50fB0"
        );
        const tx = await contract.methods.balanceOf(address).call();
        const tx1 = tx ? web3.utils.fromWei(tx, "ether") : 0;
        const availableNKTToken = tx1 ? web3.utils.fromWei(tx, "ether") : 0;

        setAvialableNKTToken(availableNKTToken);

        if (Number(tx1) > Number(NKTValuePriceInNUmber)) {
          setIsAvailableForWithdraw(true);
        } else {
          setIsAvailableForWithdraw(false);
        }

        setShowTopUpModal2(true);
        props.application.setLoading(false);
      } catch (error) {
        props.application.setLoading(false);
        console.log(error);
      }
    } else {
      props.application.setLoading(false);
      toast.dismiss();
      toast.error(`Please Wait! While NKT Price is Fetching`);
    }
  };

  const handleSpotWithdrawl = async () => {
    if (withdrawalAmount > 0) {
      const provider = window.ethereum
        ? window.ethereum
        : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);

      const web3 = new Web3(provider);

      // if (Number(avialbleSpotBalance) >= Number(withdrawalAmount)) {
      if (Number(withdrawalAmount) >= 5) {
        props.application.setLoading(true);
        const tokenAmount = web3.utils.toWei(
          withdrawalAmount.toString(),
          "ether"
        );

        const fromNakaWallet = false;
        if (window.ethereum && window.ethereum) {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const NakaContract = new ethers.Contract(
            ADDRESS,
            CONTRACTS.Nakamoto.abi,
            signer
          );
          try { 
            const value = (withdrawalAmount / Number(nKTValuePrice)).toFixed(2);
            const requiredInWallet = web3.utils.toWei(
              (
                value *
                (1 - 0.005 * Math.ceil(Number(withdrawalAmount) / 100))
              ).toString(),
              "ether"
            );

            console.log((
              value *
              (1 - 0.005 * Math.ceil(Number(withdrawalAmount) / 100))
            ).toString(), "fsdafsda");
            const transaction = await NakaContract.withdrawTokenFromWallet(
              tokenAmount,
              requiredInWallet,
              fromNakaWallet
            );
            await transaction.wait();

            props.application.setLoading(false);
            toast.dismiss();
            toast.success("Withdrawal successfully to wallet!");
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } catch (error) {
            console.log(error, "error");
            if (error.code == "UNPREDICTABLE_GAS_LIMIT") {
              props.application.setLoading(false);
              toast.dismiss();
              toast.error(`Network issue,try again after sometime`);
            } else if (error.code == "ACTION_REJECTED") {
              props.application.setLoading(false);
              toast.dismiss();
              toast.error(`User reject the transaction`);
            } else if (error.code == "4001") {
              props.application.setLoading(false);
              toast.dismiss();
              toast.error(`User reject the transaction`);
            } else {
              props.application.setLoading(false);
              toast.dismiss();
              toast.error(`Error: ${error.message}`);
            }
          }
        }
      } else {
        props.application.setLoading(false);
        toast.dismiss();
        toast.error("Minimum withdrawal amount is 5$");
      }
      // } else {
      //   props.application.setLoading(false);
      //   toast.dismiss();
      //   toast.error("Withdrawal amount exceeds available balance");
      // }
    } else {
      toast.error("Please Enter Withdrawal Amount");
    }
  };

  const handleNakaWithdrawlModal = async () => {
    const provider = window.ethereum
      ? window.ethereum
      : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);
    const web3 = new Web3(provider);
    if (NKTValuePriceInNUmber) {
      try {
        props.application.setLoading(true);
        const contract = new web3.eth.Contract(
          CONTRACTS.ERC20.abi,
          "0x5dd1cbf142F4B896D18aF835C1734363b0d50fB0"
        );
        const tx = await contract.methods.balanceOf(address).call();
        const tx1 = tx ? web3.utils.fromWei(tx, "ether") : 0;
        const availableNKTToken = tx1 ? web3.utils.fromWei(tx, "ether") : 0;

        setAvialableNKTToken(availableNKTToken);

        if (Number(tx1) > Number(NKTValuePriceInNUmber)) {
          setIsAvailableForWithdraw(true);
        } else {
          setIsAvailableForWithdraw(false);
        }

        setShowTopUpModal3(true);
        props.application.setLoading(false);
      } catch (error) {
        props.application.setLoading(false);
        console.log(error);
      }
    } else {
      props.application.setLoading(false);
      toast.dismiss();
      toast.error(`Please Wait! While NKT Price is Fetching`);
    }
  };

  const handleNakaWithdrawl = async () => {
    if (withdrawalAmount > 0) {
      const provider = window.ethereum
        ? window.ethereum
        : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);

      const web3 = new Web3(provider);
      const value = web3.utils.toWei(withdrawalAmount.toString(), "ether");

      if (withdrawalAmount >= 5) {
        props.application.setLoading(true);

        const targetTimestamp = timestamp + 540 * 24 * 60 * 60;
        const currentTimestamp = Math.floor(Date.now() / 1000);

        if (currentTimestamp >= targetTimestamp) {
          const tokenAmount = value;
          const fromNakaWallet = true;

          if (window.ethereum && window.ethereum.selectedAddress) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const NakaContract = new ethers.Contract(
              ADDRESS,
              CONTRACTS.Nakamoto.abi,
              signer
            );
            try {
              const transaction = await NakaContract.withdrawTokenFromWallet(
                tokenAmount,
                fromNakaWallet
              );
              await transaction.wait();

              props.application.setLoading(false);
              toast.dismiss();
              toast.success("Withdrawal successfully to wallet!");
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } catch (error) {
              props.application.setLoading(false);
              toast.dismiss();
              toast.error(`Error: ${error.error.message}`);
            }
          }
        } else {
          props.application.setLoading(false);
          toast.dismiss();
          toast.error("You are eligible to withdraw after 540 days.");
        }
      } else {
        props.application.setLoading(false);
        toast.dismiss();
        toast.error("Minimum withdrawal amount is 5$");
      }
    } else {
      toast.error("Please Enter Withdrawal Amount");
    }
    getUsersData();
  };

  const handleSwapOldNKT = async () => {
    if (window.ethereum) {
      props.application.setLoading(true);

      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const NakaContract = new ethers.Contract(
          ADDRESS,
          CONTRACTS.Nakamoto.abi,
          signer
        );

        const web3 = new Web3(provider);
        if (Number(nKTValuePrice) > 0) {
          const value = web3.utils.toWei(nKTValuePrice.toString(), "ether");
          const swapOldNKT = await NakaContract.swapOldNKT(value);
          await swapOldNKT.wait();
        }
        props.application.setLoading(false);
        toast.dismiss();
        toast.success("Swap Old NaKa Token successfully!");
      } catch (error) {
        if (error.code === "ACTION_REJECTED") {
          props.application.setLoading(false);
          toast.dismiss();
          toast.error(`User reject the transaction`);
        } else {
          props.application.setLoading(false);
          toast.dismiss();
          toast.error(`Error: Something went wrong`);
        }
        console.log(error, "error");
      }
    } else {
      props.application.setLoading(true);
      setCurrentFunctionIndex(2);
      write({
        address: ADDRESS,
        abi: CONTRACTS.Nakamoto.abi,
        functionName: "swapOldNKT",
      });
    }
  };

  const convertTimestampToDateTime = (timestamp) => {
    const date = new Date(timestamp * 1000);

    const year = date.getUTCFullYear();
    const month = `0${date.getUTCMonth() + 1}`.slice(-2);
    const day = `0${date.getUTCDate()}`.slice(-2);
    let hours = date.getUTCHours();
    const minutes = `0${date.getUTCMinutes()}`.slice(-2);
    const seconds = `0${date.getUTCSeconds()}`.slice(-2);

    let period = "AM";
    if (hours >= 12) {
      period = "PM";
      hours -= 12;
    }
    if (hours === 0) {
      hours = 12;
    }

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${period}`;

    return formattedDateTime;
  };

  const getClaimRoyalityIncome = async () => {
    try {
      props.application.setLoading(true);
      if (address) {
        const provider = window.ethereum
          ? window.ethereum
          : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);
        const web3 = new Web3(provider);
        const contract = new web3.eth.Contract(CONTRACTS.Nakamoto.abi, ADDRESS);
        const receipt = await contract.methods
          .getIsApplicableforRoyaltyIncome(address)
          .call();
        setIsApplicable(receipt.isApplicable_);
        props.application.setLoading(false);
      } else {
        console.log("No adddress found");
        props.application.setLoading(false);
      }
    } catch (err) {
      console.log(err, "err");
      props.application.setLoading(false);
      toast.error(
        "Can't fetch claim royalty income, Try again after sometimes"
      );
    }
  };

  const handleClaimRoyality = async () => {
    if (window.ethereum) {
      const provider = window.ethereum
        ? window.ethereum
        : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);

      const web3 = new Web3(provider);
      try {
        const contract = new web3.eth.Contract(CONTRACTS.Nakamoto.abi, ADDRESS);

        const tx = contract.methods.claimRoyaltyIncome();
        props.application.setLoading(true);
        const gas = await tx.estimateGas({ from: address });
        const gasPrice2 = await web3.eth.getGasPrice(); // Get current gas price from the network
        const receipt = await tx.send({
          from: address,
          gas: gas,
          gasPrice: gasPrice2 * 1.2,
        });

        props.application.setLoading(false);
        toast.success("Royalty check-mark successfully");
      } catch (error) {
        toast.error(error.message);
        props.application.setLoading(false);
      }
    } else {
      props.application.setLoading(true);
      setCurrentFunctionIndex(3);
      write({
        address: ADDRESS,
        abi: CONTRACTS.Nakamoto.abi,
        functionName: "claimRoyaltyIncome",
      });
    }
  };
  const getTokenPrice = async () => {
    if (loading) {
      return;
    }
    try {
      setLoading(true);
      const price = await getPrice(
        1, //Amount of token 1
        "https://polygon-mainnet.infura.io/v3/db35afb7fa5b4454b7f159f24c0411ef", // RPC url
        "WQGDRA3YZDMSFEUVUKRSEDZS3FGGTMCG6W", // Block Scan Api Key
        "0x1B8C03343473Bc648c1b82c85C1DD32C9d63430e", // Pool Address
        "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6" // Quoter Address
      );
      setNKTValuePriceInNUmber(Number(1 / Number(price)));
      setNKTValuePrice(price);
      setLoading(false);
    } catch (ex) {
      setLoading(false);
      console.log(ex, "price not fetch");
    }
  };

  const getTotalWithdrawal = async () => {
    try {
      props.application.setLoading(true);
      if (address) {
        const provider = window.ethereum
          ? window.ethereum
          : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);
        const web3 = new Web3(provider);
        const contract = new web3.eth.Contract(CONTRACTS.Nakamoto.abi, ADDRESS);
        const receipt = await contract.methods.usersWithdraw(address).call();
        setWithdrawalDetails(receipt);
        props.application.setLoading(false);
      } else {
        console.log("No adddress found");
        props.application.setLoading(false);
      }
    } catch (err) {
      console.log(err, "err");
      props.application.setLoading(false);
    }
  };

  useEffect(() => {
    getTokenPrice();
    getUsersData();
    getClaimRoyalityIncome();
    const isActiveCheck = localStorage.getItem("isActive");
    if (isActiveCheck === "false") {
      setIsActive(false);
    }

    getTotalWithdrawal();
  }, []);

  const getTotalBuisness = async () => {
    try {
      props.application.setLoading(true);
      if (address) {
        const provider = window.ethereum
          ? window.ethereum
          : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);
        const web3 = new Web3(provider);
        const contract = new web3.eth.Contract(CONTRACTS.Nakamoto.abi, ADDRESS);
        const receipt = await contract.methods
          .getTotalBusinessWithTeam(address, 0)
          .call();
        setTotalBuisnessWithTeam(
          receipt && receipt.totalBusinessWithTeam_ / 1e18
        );
        props.application.setLoading(false);
      } else {
        console.log("No adddress found");
        props.application.setLoading(false);
      }
    } catch (err) {
      console.log(err, "err");
      props.application.setLoading(false);
      toast.error(
        "Can't fetch claim royalty income, Try again after sometimes"
      );
    }
  };

  let requestCounter = 0;
  const [countRoyalty, setCountRoyalty] = useState(0);
  const getCalculateLegsBuisness = async (address) => {
    requestCounter++;
    try {
      // props.application.setLoading(true);
      const provider = window.ethereum
        ? window.ethereum
        : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);

      const web3 = new Web3(provider);
      const contract = new web3.eth.Contract(CONTRACTS.Nakamoto.abi, ADDRESS);

      //Get Current User
      const receipt = await contract.methods.getUser(address).call();
      // setCountRoyalty(receipt.);
      console.log(receipt.claimedRoyalties.length);
      setCountRoyalty(receipt.claimedRoyalties.length);
      const investedAmount = receipt.totalInvestment / 1e18;
      const sponsorsTotalBuisness = [];
      if (receipt.sponsors.length > 0) {
        for (let index = 0; index < receipt.sponsors.length; index++) {
          const receiptTotalBuisness = await contract.methods
            .getTotalBusinessWithTeam(receipt.sponsors[index], 0)
            .call();
          sponsorsTotalBuisness.push(
            receiptTotalBuisness.totalBusinessWithTeam_
          );
        }
      }
      var superLegBuisness = 0;
      var otherLegBuisness = 0;
      for (var i = 0; i < sponsorsTotalBuisness.length; i++) {
        otherLegBuisness += sponsorsTotalBuisness[i] / 1e18;
        if (sponsorsTotalBuisness[i] / 1e18 > superLegBuisness) {
          superLegBuisness = sponsorsTotalBuisness[i] / 1e18;
        }
      }

      otherLegBuisness -= superLegBuisness;
      otherLegBuisness += investedAmount;

      setOtherLegBuisness(otherLegBuisness);
      setSuperLegBuisness(superLegBuisness);
    } catch (err) {
      console.log(err);
    } finally {
      requestCounter--;
      if (requestCounter === 0) {
        setFetchingStatus(true);
      }
    }
  };

  useEffect(() => {
    getCalculateLegsBuisness(address);
    getTotalBuisness();
  }, []);

  return (
    <>
      <section className="wallet-balance">
        {isActive === false && (
          <div className="no-activerecords mb-3">
            <h6>Your account is no longer active please contact us.</h6>
          </div>
        )}
        {isHold === true && (
          <div className="no-holdrecords mb-3">
            <h6>
              Your Account is on Hold except non-working income all other
              incomes are on Hold.
            </h6>
          </div>
        )}
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 ftyyer">
              <div className="usdt-balance">
                <h5>Spot Balance</h5>
                <h2>{Number(avialbleSpotBalance).toFixed(4)} USDT</h2>
                <button onClick={handleSpotWithdrawlModal}> Withdraw </button>
                <p>
                  Total Spot Balance - {Number(spotBalance).toFixed(4)} USDT
                </p>
              </div>
            </div>
            <div className="col-md-6 ">
              <div className="usdt-balance">
                <div className="swap-btn">
                  <h5>Naka Balance</h5>
                </div>
                <h2>{Number(avialbleNakaBalance).toFixed(4)} USDT</h2>
                {}
                {phase === "0" ? (
                  <button onClick={handleNakaWithdrawlModal}> Withdraw </button>
                ) : (
                  <button onClick={handleNakaWithdrawl}> Swap</button>
                )}

                <p>
                  Total Naka Balance - {Number(isNktBalance).toFixed(4)} USDT
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="total-earinig">
        <div className="user-full-details">
          <div className="retopup">
            <div className="trtfy new-tffty">
              <div className="tbing-transaction">
                <h6>Analytics </h6>
                <div className="table-responsive">
                  <table>
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Today(USDT)</th>
                        <th scope="col">Total(USDT)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Non-Working Income:</td>
                        <td>{Number(todayNonWorkingIncome).toFixed(4)}</td>
                        <td>{Number(nonWorkingIncome).toFixed(4)} </td>
                      </tr>
                      <tr>
                        <td>Sponsor Income:</td>
                        <td>{Number(todaySponsorIncome).toFixed(4)}</td>
                        <td>
                          {Number(sponsorIncome).toFixed(4)}

                          {"  "}
                        </td>
                      </tr>

                      <tr>
                        <td>Team Build Bonus:</td>
                        <td>{Number(todayTeamBuildBonus).toFixed(4)}</td>
                        <td>
                          {`${Number(teamBuildBonus).toFixed(4)}`}
                          {"  "}
                        </td>
                      </tr>

                      <tr>
                        <td>Boost Income:</td>
                        <td>{Number(todayBoostIncome).toFixed(4)}</td>
                        <td>
                          {" "}
                          {Number(boostIncome).toFixed(4)}
                          {"  "}
                        </td>
                      </tr>

                      <tr>
                        <td>Royalty income:</td>
                        <td>{Number(todayRoyaltyIncome).toFixed(4)}</td>
                        <td>
                          {" "}
                          {Number(royaltyIncome).toFixed(4)}
                          {"  "}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="border-r-new"></div>
            <div className="trtfy usdt-income">
              <div className="claimtoken">
                {/* {isApplicable && (
                  <button onClick={handleClaimRoyality}>Claim Royalty</button>
                )} */}
                {swapAvialable && (
                  <>
                    {loading === true || nKTValuePrice === null ? (
                      <>
                        <Lottie
                          style={{ height: 32, margin: 0 }}
                          animationData={loadingAnimation}
                          loop={true}
                        />
                        <span>Fetching please wait...</span>
                      </>
                    ) : (
                      <>
                        <button onClick={handleSwapOldNKT}>Swap Token </button>
                        <span>
                          {" "}
                          {web3Instance?.utils.fromWei(
                            swapValue.toString(),
                            "ether"
                          )}{" "}
                          NKT =
                          {(
                            nKTValuePrice *
                            parseFloat(
                              web3Instance?.utils.fromWei(
                                swapValue.toString(),
                                "ether"
                              )
                            )
                          ).toFixed(4)}{" "}
                          USDT
                        </span>
                      </>
                    )}
                  </>
                )}
              </div>
              <h2>
                Today's Earnings <br />
                <span>{Number(todaysTotalIncome).toFixed(4)} USDT </span>
              </h2>
              <h2>
                Total Earnings <br />
                <span>
                  {`${(
                    Number(nonWorkingIncome) +
                    Number(sponsorIncome) +
                    Number(boostIncome) +
                    Number(royaltyIncome) +
                    Number(teamBuildBonus)
                  ).toFixed(4)}`}{" "}
                  USDT{" "}
                </span>
              </h2>

              <button className="wallet-balance" onClick={getTransactions}>
                Transaction History
              </button>
              <p>
                Last withdrawal:
                {timeDifference !== null ? (
                  <span> {timeDifference}</span>
                ) : (
                  <span> No last withdrawal found..</span>
                )}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="total-earinig">
        <div className="user-full-details">
          <div className="retopup">
            <div className="trtfy new-tffty">
              <div className="tbing-transaction">
                <div className="status-roylate-income">
                  <h6>Royalty Status</h6>
                  <div className="claimtoken">
                    {isApplicable && (
                      <button onClick={handleClaimRoyality}>
                        Claim Royalty
                      </button>
                    )}
                  </div>
                </div>

                {/* <nav className="royaltenav">
                  <ul>
                    <li className="kfkfkfk">
                      <a>Royality</a>
                      <ul className="super-leg">
                        <li>
                          <a>Super Leg</a>
                          <h6
                            style={{
                              color: countRoyalty > 0 ? "green" : "white",
                            }}
                          >
                            {" "}
                            21,000 $ * 60% = 12,600 $
                          </h6>
                          <h6
                            style={{
                              color: countRoyalty > 1 ? "green" : "white",
                            }}
                          >
                            {" "}
                            50,000 $ * 60% = 30,000 $
                          </h6>
                          <h6
                            style={{
                              color: countRoyalty > 2 ? "green" : "white",
                            }}
                          >
                            1,00,000 $ * 60% = 60,000 $
                          </h6>
                          <h6
                            style={{
                              color: countRoyalty > 3 ? "green" : "white",
                            }}
                          >
                            2,00,000 $ * 60% = 120,000 $
                          </h6>

                          {`${superLegBuisness} $`}
                        </li>
                        <li>
                          <a>Other Leg</a>
                          <h6
                            style={{
                              color: countRoyalty > 0 ? "green" : "white",
                            }}
                          >
                            {" "}
                            21,000 $ * 40% = 8,400 $
                          </h6>{" "}
                          <h6
                            style={{
                              color: countRoyalty > 1 ? "green" : "white",
                            }}
                          >
                            {" "}
                            50,000 $ * 40% = 20,000 $
                          </h6>
                          <h6
                            style={{
                              color: countRoyalty > 2 ? "green" : "white",
                            }}
                          >
                            1,00,000 $ * 40% = 40,000 $
                          </h6>
                          <h6
                            style={{
                              color: countRoyalty > 3 ? "green" : "white",
                            }}
                          >
                            2,00,000 $ * 40% = 80,000 $
                          </h6>
                          {`${otherLegBuisness} $`}
                        </li>
                      </ul>
                    </li>
                  </ul>
                </nav> */}

                <nav className="royaltenav">
                  <ul>
                    <li className="kfkfkfk">
                      <a>Royalty</a>
                      <ul className="super-leg">
                        <li className="tab-navsup">
                          <a>
                            <span style={{ color: "white" }}>Super Leg</span>{" "}
                            {/* <span>{`(${superLegBuisness} $)`}</span>
                             */}
                            <span>{`(${Number(superLegBuisness).toFixed(
                              2
                            )} $)`}</span>
                          </a>{" "}
                          <div style={{ height: "20px" }}></div>
                          <div className="table-responsive leg-income">
                            <table>
                              <thead>
                                <tr>
                                  <th>Amount</th>
                                  <th>Calculation</th>
                                  <th>Result</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>21,000 $</td>
                                  <td> * 60%</td>
                                  <td
                                    style={{
                                      color:
                                        countRoyalty > 0 ? "green" : "white",
                                    }}
                                  >
                                    12,600 $
                                  </td>
                                  <td>
                                    {superLegBuisness > 12600 ? (
                                      <img
                                        src="/assets/img/check-mark.png"
                                        alt="icon"
                                      />
                                    ) : (
                                      <img
                                        src="/assets/img/no.png"
                                        alt="icon"
                                      />
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>50,000 $</td>
                                  <td>* 60%</td>
                                  <td
                                    style={{
                                      color:
                                        countRoyalty > 1 ? "green" : "white",
                                    }}
                                  >
                                    30,000 $
                                  </td>
                                  <td>
                                    {superLegBuisness > 30000 ? (
                                      <img
                                        src="/assets/img/check-mark.png"
                                        alt="icon"
                                      />
                                    ) : (
                                      <img
                                        src="/assets/img/no.png"
                                        alt="icon"
                                      />
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>1,00,000 $</td>
                                  <td>* 60%</td>
                                  <td
                                    style={{
                                      color:
                                        countRoyalty > 2 ? "green" : "white",
                                    }}
                                  >
                                    60,000 $
                                  </td>
                                  <td>
                                    {superLegBuisness > 60000 ? (
                                      <img
                                        src="/assets/img/check-mark.png"
                                        alt="icon"
                                      />
                                    ) : (
                                      <img
                                        src="/assets/img/no.png"
                                        alt="icon"
                                      />
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>2,00,000 $</td>
                                  <td>* 60%</td>
                                  <td
                                    style={{
                                      color:
                                        countRoyalty > 3 ? "green" : "white",
                                    }}
                                  >
                                    120,000 $
                                  </td>
                                  <td>
                                    {superLegBuisness > 120000 ? (
                                      <img
                                        src="/assets/img/check-mark.png"
                                        alt="icon"
                                      />
                                    ) : (
                                      <img
                                        src="/assets/img/no.png"
                                        alt="icon"
                                      />
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </li>
                        <li className="tab-navsup">
                          <a>
                            <span style={{ color: "white" }}>Other Leg</span>{" "}
                            <span>{`(${Number(otherLegBuisness).toFixed(
                              2
                            )} $)`}</span>
                          </a>{" "}
                          <div style={{ height: "20px" }}></div>
                          <div className="table-responsive">
                            <table>
                              <thead>
                                <tr>
                                  <th>Amount</th>
                                  <th>Calculation</th>
                                  <th>Result</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>21,000 $</td>
                                  <td>* 40%</td>
                                  <td
                                    style={{
                                      color:
                                        countRoyalty > 0 ? "green" : "white",
                                    }}
                                  >
                                    8,400 $
                                  </td>
                                  <td>
                                    {otherLegBuisness > 8400 ? (
                                      <img
                                        src="/assets/img/check-mark.png"
                                        alt="icon"
                                      />
                                    ) : (
                                      <img
                                        src="/assets/img/no.png"
                                        alt="icon"
                                      />
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>50,000 $</td>
                                  <td>* 40%</td>
                                  <td
                                    style={{
                                      color:
                                        countRoyalty > 1 ? "green" : "white",
                                    }}
                                  >
                                    20,000 $
                                  </td>
                                  <td>
                                    {otherLegBuisness > 20000 ? (
                                      <img
                                        src="/assets/img/check-mark.png"
                                        alt="icon"
                                      />
                                    ) : (
                                      <img
                                        src="/assets/img/no.png"
                                        alt="icon"
                                      />
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>1,00,000 $</td>
                                  <td>* 40%</td>
                                  <td
                                    style={{
                                      color:
                                        countRoyalty > 2 ? "green" : "white",
                                    }}
                                  >
                                    40,000 $
                                  </td>
                                  <td>
                                    {otherLegBuisness > 40000 ? (
                                      <img
                                        src="/assets/img/check-mark.png"
                                        alt="icon"
                                      />
                                    ) : (
                                      <img
                                        src="/assets/img/no.png"
                                        alt="icon"
                                      />
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>2,00,000 $</td>
                                  <td>* 40%</td>
                                  <td
                                    style={{
                                      color:
                                        countRoyalty > 3 ? "green" : "white",
                                    }}
                                  >
                                    80,000 $
                                  </td>
                                  <td>
                                    {otherLegBuisness > 80000 ? (
                                      <img
                                        src="/assets/img/check-mark.png"
                                        alt="icon"
                                      />
                                    ) : (
                                      <img
                                        src="/assets/img/no.png"
                                        alt="icon"
                                      />
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </nav>

                {/* <div>
                    <a> </a>
                    <h6>
                      {" "}
                      <img src="/assets/img/check-mark.png" alt="icon" />
                    </h6>
                    <h6>
                      {" "}
                      <img src="/assets/img/check-mark.png" alt="icon" />
                    </h6>
                    <h6>
                      {" "}
                      <img src="/assets/img/check-mark.png" alt="icon" />
                    </h6>
                    <h6>
                      {" "}
                      <img src="/assets/img/check-mark.png" alt="icon" />
                    </h6>
                    <h6>
                      {" "}
                      <img src="/assets/img/check-mark.png" alt="icon" />
                    </h6>
                  </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal show={showTopUpModal} onHide={handleCloseTopUpModal}>
        <div className="withdraw">
          <Modal.Header closeButton>
            <Modal.Title>Transaction History</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table>
              {transactions && transactions.length > 0 ? (
                <thead>
                  <tr>
                    <th scope="col">S.No.</th>
                    <th scope="col">Wallet</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Time</th>
                  </tr>
                </thead>
              ) : (
                <div>
                  <span>No Transaction history...</span>
                </div>
              )}
              <tbody>
                {transactions && transactions.length > 0 && (
                  <>
                    {transactions
                      .slice()
                      .reverse()
                      .map((item, index) => {
                        const amount = item.amount;
                        return (
                          <tr key={index}>
                            <td>{index + 1}.</td>
                            <td>
                              {item.isFromNakaWallet === true
                                ? " NaKa Wallet"
                                : "Spot Wallet"}
                            </td>
                            <td>
                              {web3Instance?.utils.fromWei(
                                amount.toString(),
                                "ether"
                              ) &&
                                parseFloat(
                                  web3Instance.utils.fromWei(
                                    amount.toString(),
                                    "ether"
                                  )
                                ).toFixed(4)}{" "}
                              USDT
                            </td>
                            <td>
                              {convertTimestampToDateTime(item.timestamp)}
                            </td>
                          </tr>
                        );
                      })}
                  </>
                )}
              </tbody>
            </table>
            <div className="balance-details">
              <h6>
                Total Withdrawal <span>(Including old withdrawals)</span>
              </h6>
              <div className="withamount">
                <p>
                  Naka Wallet :{" "}
                  <span>
                    {Number(
                      withdrawalDetails.totalNakaWalletWithdraw &&
                        web3Instance?.utils.fromWei(
                          withdrawalDetails.totalNakaWalletWithdraw,
                          "ether"
                        )
                    ).toFixed(4)}{" "}
                    USDT
                  </span>
                </p>
                <p>
                  Spot Wallet :{" "}
                  <span>
                    {Number(
                      withdrawalDetails.totalSpotWalletWithdraw &&
                        web3Instance?.utils.fromWei(
                          withdrawalDetails.totalSpotWalletWithdraw,
                          "ether"
                        )
                    ).toFixed(4)}{" "}
                    USDT
                  </span>
                </p>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      <Modal show={showTopUpModal2} onHide={handleCloseTopUpModal2}>
        <div className="withdraw nktmodal">
          <Modal.Header closeButton>
            <Modal.Title>Spot Wallet Withdraw </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Lottie
              style={{ height: 150, margin: 0 }}
              animationData={withdrawAnimation}
              loop={true}
            />
            <div className="wallet-balance">
              <div className="usdt-balance">
                <h5>Available NKT : {Number(avialableNKTToken).toFixed(4)} </h5>
                <h5>Required NKT : {Math.ceil(NKTValuePriceInNUmber)}</h5>
                <form>
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Enter Amount to Withdraw"
                    value={withdrawalAmount}
                    onChange={handleChange}
                  />
                  {error && <div className="invalid-feedback">{error}</div>}
                </form>
                {withdrawalAmount && (
                  <p>
                    {withdrawalAmount} USDT ={" "}
                    {(withdrawalAmount / Number(nKTValuePrice)).toFixed(2)} NKT
                  </p>
                )}
                <button
                  className="modalwithdraw"
                  onClick={() => {
                    setWithdrawalAmount(avialbleSpotBalance);
                  }}
                >
                  MAX
                </button>
                {isAvialableForWithdraw ? (
                  <button
                    className="modalwithdraw"
                    onClick={handleSpotWithdrawl}
                  >
                    Withdraw
                  </button>
                ) : (
                  <p>
                    You must have at least {Math.ceil(NKTValuePriceInNUmber)}{" "}
                    NKT for withdrawal
                  </p>
                )}
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      <Modal show={showTopUpModal3} onHide={handleCloseTopUpModal3}>
        <div className="withdraw">
          <Modal.Header closeButton>
            <Modal.Title>Naka Wallet Withdraw </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Lottie
              style={{ height: 150, margin: 0 }}
              animationData={withdrawAnimation}
              loop={true}
            />
            <div className="wallet-balance">
              <div className="usdt-balance">
                <h5>Available NKT : {Number(avialableNKTToken).toFixed(2)} </h5>
                <h5>Required NKT : {Math.ceil(NKTValuePriceInNUmber)}</h5>
                <form>
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Enter Amount to Withdraw"
                    value={withdrawalAmount}
                    onChange={handleChange}
                  />
                  {error && <div className="invalid-feedback">{error}</div>}
                </form>
                {NKTValuePriceInNUmber > 0 && isAvialableForWithdraw ? (
                  <button
                    className="modalwithdraw"
                    onClick={handleNakaWithdrawl}
                  >
                    Withdraw
                  </button>
                ) : (
                  <p>
                    You must have at least {Math.ceil(NKTValuePriceInNUmber)}{" "}
                    NKT for withdrawal
                  </p>
                )}
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default injectModels(["admin", "application"])(Wallet);
