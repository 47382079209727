import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./components/Home";
import UnAunthenticRoute from "./routes/PublicRoute";
import AuthenticRoute from "./routes/DashboardRoute";
import { WagmiConfig } from "wagmi";
import Dashboard from "./components/Dashboard/Dashboard";
import Error from "./components/Error";
import TimeLine from "./components/Dashboard/TimeLine";
import PrivacyPolicy from "./components/Home/PrivacyPolicy";
import TermsCondition from "./components/Home/TermsCondition";
import About from "./components/Home/About";
import Contact from "./components/Home/Contact";
import NakamotoSchool from "./components/Home/NakamotoSchool";
import Network from "./components/Dashboard/Network";
import Tutorial from "./components/Dashboard/Tutorial";
import Notification from "./components/Dashboard/Notification";
import Wallet from "./components/Dashboard/Wallet";
import EditProfile from "./components/Dashboard/EditProfile";
import FAQ from "./components/Home/FAQ";
import LoaderFile from "./components/loader/LoaderFile";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { arbitrum, bscTestnet, mainnet } from "wagmi/chains";
import ComingSoon from "./components/Home/ComingSoon";
import Report from "./components/Dashboard/Report";
import PleaseWait from "./constants/PleaseWait";
import Investments from "./components/Dashboard/Investments";

const projectId = "f02b2304b61f62fda06d2ac224312444";

const metadata = {
  name: "Web3Modal",
  description: "Web3Modal Example",
  url: "https://web3modal.com",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = [arbitrum, mainnet, bscTestnet];
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });

createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  featuredWalletIds: [
    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96", //Metamask
    "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0", //Trust Wallet
    "20459438007b75f4f4acb98bf29aa3b800550309646d375da5fd4aac6c2a2c66", //Token Pocket
  ],
  includeWalletIds: [
    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96", //Metamask
    "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0", //Trust Wallet
    "20459438007b75f4f4acb98bf29aa3b800550309646d375da5fd4aac6c2a2c66", //Token Pocket
  ],
  excludeWalletIds: [
    "fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa", //Coinbase
  ],
});

const App = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleButton = (event) => {
    event.preventDefault();
    setIsOpen(!isOpen);
  };
  const toggleButton1 = (event) => {
    event.preventDefault();
    setIsOpen(false);
  };

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        includedLanguages: "en,ja",
        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
      },
      "google_translate_element"
    );
  };

  const eventDate = new Date("2024-02-13T14:35:00.000Z").getTime();

  const currentDate = new Date().getTime();

  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript).after();
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  return (
    <React.Fragment>
      {/* {
        currentDate> eventDate? */}
      <Router>
        <Toaster reverseOrder={true} />
        <LoaderFile />
        <WagmiConfig config={wagmiConfig}>
          <Switch>
            <UnAunthenticRoute exact path="/" component={Home} />
            <UnAunthenticRoute
              exact
              path={"/invite" + "/:referId"}
              component={Home}
            />
            <UnAunthenticRoute
              exact
              path="/privacy-policy"
              component={PrivacyPolicy}
            />
            <UnAunthenticRoute exact path="/faq" component={FAQ} />
            <UnAunthenticRoute
              exact
              path="/terms-and-condition"
              component={TermsCondition}
            />
            <UnAunthenticRoute exact path="/about-us" component={About} />
            <UnAunthenticRoute exact path="/contact" component={Contact} />
            <UnAunthenticRoute
              exact
              path="/nakamoto-school"
              component={NakamotoSchool}
            />
            <AuthenticRoute exact path="/dashboard" component={Dashboard} />
            <AuthenticRoute
              exact
              path="/dashboard/timeline"
              component={TimeLine}
            />
            <AuthenticRoute
              exact
              path="/dashboard/network"
              component={Network}
            />
            <AuthenticRoute
              exact
              path="/dashboard/investments"
              component={Investments}
            />
            <AuthenticRoute
              exact
              path="/dashboard/tutorial"
              component={Tutorial}
            />
            <AuthenticRoute exact path="/dashboard/wallet" component={Wallet} />
            <AuthenticRoute
              exact
              path="/dashboard/notification"
              component={Notification}
            />
            <AuthenticRoute
              exact
              path="/dashboard/edit-profile"
              component={EditProfile}
            />

            <AuthenticRoute exact path="/dashboard/report" component={Report} />

            <Route exact path="/under-maintenance" component={PleaseWait} />
            <Route path="/*" component={Error} />
          </Switch>
        </WagmiConfig>
      </Router>
      {/* :<ComingSoon/>
       */}
      <div
        className={`dt-style-picker-wrapper lang-selector ${
          isOpen ? "openss" : ""
        }`}
      >
        <div className="dt-style-picker">
          <a className="style-picker-ico" onClick={toggleButton}>
            <i className="fa-solid fa-globe"></i>
          </a>
          <div className="abc">
            <div id="google_translate_element" onClick={toggleButton1}>
              <div className="skiptranslate goog-te-gadget" dir="ltr"></div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default App;

/* file created by sourav mishra on  07/09/2023 */
