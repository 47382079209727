import React, { useState, useEffect } from "react";
import { injectModels } from "../../Redux/injectModels";
import { useAccount } from "wagmi";
import Badge from "@mui/material/Badge";

const Notification = (props) => {
  const [notification, setNotification] = useState([]);
  const { address } = useAccount({
    onConnect({ address, connector, isReconnected }) {},
    onDisconnect() {
      window.location.reload();
    },
  });

  useEffect(() => {
    getUserNotificationData();
    handleUserNotificationRead();
  }, []);

  const getUserNotificationData = async () => {
    try {
      props.application.setLoading(true);
      const response = await props.admin.getUserNotificationDetail(address);

      if (response.status === 201) {
        const data = response.data.user.Notification.reverse();
        setNotification(data);
      }
      props.application.setLoading(false);
    } catch (error) {
      console.error(error);
      props.application.setLoading(false);
    }
  };

  const handleUserNotificationRead = async (id) => {
    try {
      props.application.setLoading(true);
      const response = await props.admin.UserNotificationUpdates(address);

      if (response.success === true) {
        getUserNotificationData();
      }
      props.application.setLoading(false);
    } catch (error) {
      console.error(error);
      props.application.setLoading(false);
    }
  };

  return (
    <section className="notification">
      {notification.length > 0 ? (
        <div className="container-fluid">
          {notification.map((item, index) => (
            <div className="row mb-2" key={index}>
              <div className=" col-xxl-7 col-lg-8 col-md-9 col-12">
                <div className="notification-inner">
                  {item.isRead === true ? (
                    <div className="noti">
                      <i className="fa-regular fa-bell"></i>
                    </div>
                  ) : (
                    <Badge color="success" variant="dot">
                      <div className="noti">
                        <i className="fa-regular fa-bell"></i>
                      </div>
                    </Badge>
                  )}

                  <div className="hfgtt">
                    <h6>{item.notificationId.name}</h6>
                    <p>{item.notificationId.description}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="no-records">
          <h6>Notifications not available</h6>
        </div>
      )}
    </section>
  );
};

export default injectModels(["admin", "application"])(Notification);
