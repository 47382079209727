import React, { useEffect, useRef, memo } from 'react';
import './TransitionGraph.css'; // Assuming you will add your CSS styles here

function TransitionGraph() {
  const container = useRef();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
    {
      "symbols": [
        [
          "UNISWAP3POLYGON:NKTUSDT_1B8C03.USD|1M"
        ]
      ],
      "chartOnly": false,
      "width": "100%",
      "height": "100%",
      "locale": "en",
      "colorTheme": "dark",
      "autosize": true,
      "showVolume": false,
      "showMA": false,
      "hideDateRanges": false,
      "hideMarketStatus": false,
      "hideSymbolLogo": false,
      "scalePosition": "right",
      "scaleMode": "Normal",
      "fontFamily": "-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif",
      "fontSize": "10",
      "noTimeScale": false,
      "valuesTracking": "1",
      "changeMode": "price-and-percent",
      "chartType": "area",
      "maLineColor": "#2962FF",
      "maLineWidth": 1,
      "maLength": 9,
      "lineWidth": 2,
      "lineType": 0,
      "dateRanges": [
        "1d|5",
        "1w|5",
        "1m|30",
        "3m|60",
        "12m|1D",
        "60m|1W",
        "all|1M"
      ],
      "range": "60m|1W"
    }`;
    container.current.appendChild(script);
  }, []);

  return (
    <div>
      <section className="transgraph">
        <div className="bg-heading-roadmap">
          <h2>
            <span>Graph</span>
          </h2>
        </div>
        <div className="graph-view">
          <div className="tradingview-widget-container" ref={container}>
            <div className="tradingview-widget-container__widget"></div>
            <div className="tradingview-widget-copyright"></div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default memo(TransitionGraph);



