import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { injectModels } from "../../Redux/injectModels";

const Contact = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errorFirstName, setErrorFirstName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleFirstName = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      setErrorFirstName("First Name is required");
    } else {
      setErrorFirstName("");
    }
    setFirstName(val.trim());
  };
  const handleLastName = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      setErrorLastName("Last Name is required");
    } else {
      setErrorLastName("");
    }
    setLastName(val.trim());
  };
  const handleEmail = (e) => {
    e.preventDefault();
    const val = e.target.value;

    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (val.trim() === "") {
      setErrorEmail("Email is required");
    } else if (!regEmail.test(val)) {
      setErrorEmail("Invalid Email");
    } else {
      setErrorEmail("");
    }
    setEmail(val.trim());
  };
  const handleMessage = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === " ") {
      setErrorMessage("Message is required");
    } else {
      setErrorMessage("");
    }
    setMessage(val.trimStart());
  };

  const handleContactSubmit = async (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (submitted) return;
    setSubmitted(true);

    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let valid = true;
    if (firstName.trim() === "") {
      setErrorFirstName("First Name is required");
      valid = false;
    } else {
      setErrorFirstName("");
    }
    if (lastName.trim() === "") {
      setErrorLastName("Last Name is required");
      valid = false;
    } else {
      setErrorLastName("");
    }
    if (email === "") {
      setErrorEmail("Email is required");
      valid = false;
    } else if (!regEmail.test(email)) {
      setErrorEmail(" Invalid Email");
      valid = false;
    } else {
      setErrorEmail("");
    }
    if (message.trim() === "") {
      setErrorMessage("Message is required");
      valid = false;
    } else {
      setErrorMessage("");
    }
    if (!valid) {
      return;
    } else {
      try {
        const data = {
          firstName: firstName && firstName,
          lastName: lastName && lastName,
          email: email && email,
          message: message && message,
        };
        const response = await props.admin.addContactUs(data);
        props.application.setLoading(true);
        if (response.status === 200) {
          toast.dismiss();
          toast.success(response.data.message);
          setEmail("");
          setFirstName("");
          setLastName("");
          setMessage("");
          setSubmitted(false);
          props.application.setLoading(false);
        } else {
          toast.dismiss();
          toast.error(response.data.message);
          setSubmitted(false);
          props.application.setLoading(false);
        }
      } catch (error) {
        console.log(error);
        toast.dismiss();
        toast.error(error.message);
        setSubmitted(false);
        props.application.setLoading(false);
      }
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="contact">
        <div className="container">
          <div className="heading-contact">
            <h2>
              {" "}
              <span> Contact Us </span>
            </h2>
          </div>
          <div className="row">
            <div className="col-md-6 ctn">
              <div className="contact-inner">
                <h2>Get in Touch</h2>
                <p>Leave us a Message</p>
                <form onSubmit={handleContactSubmit}>
                  <div className="row">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 ">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">First Name*</label>
                        <input
                          type="text"
                          className="form-control is-invalid"
                          id="exampleInputEmail1"
                          autoComplete="off"
                          aria-describedby="emailHelp"
                          placeholder="First Name"
                          value={firstName}
                          onChange={handleFirstName}
                        />
                        {errorFirstName && (
                          <div className="invalid-feedback">
                            {errorFirstName}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">
                          Last Name*
                        </label>
                        <input
                          type="text"
                          className="form-control is-invalid"
                          id="exampleInputPassword1"
                          aria-describedby="emailHelp"
                          autoComplete="off"
                          placeholder="Last Name"
                          value={lastName}
                          onChange={handleLastName}
                        />
                        {errorLastName && (
                          <div className="invalid-feedback">
                            {errorLastName}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Email*</label>
                    <input
                      type="email"
                      className={`form-control ${
                        errorEmail ? "is-invalid" : ""
                      }`}
                      id="exampleInputPassword1"
                      aria-describedby="emailHelp"
                      placeholder="Email"
                      value={email}
                      onChange={handleEmail}
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                      autoComplete="off"
                    />
                    {errorEmail && (
                      <div className="invalid-feedback">{errorEmail}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleFormControlTextarea1">
                      Message*
                    </label>
                    <textarea
                      className={`form-control ${
                        errorMessage ? "is-invalid" : ""
                      }`}
                      id="exampleFormControlTextarea1"
                      aria-describedby="emailHelp"
                      rows="3"
                      placeholder="Message"
                      value={message}
                      onChange={handleMessage}
                    ></textarea>
                    {errorMessage && (
                      <div className="invalid-feedback">{errorMessage}</div>
                    )}
                  </div>
                  <button
                    type="submit"
                    disabled={submitted}
                    className="submit-btn"
                  >
                    Send Message
                  </button>
                </form>
              </div>
            </div>

            <div className="col-md-6">
              <div className="contact-inner-img">
                <img
                  src="https://api.nakatoken.io/images/12cf4d21a3b34aed04548f997e444583.1707488513218.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default injectModels(["admin", "application"])(Contact);
