import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import admin from "./Models/Admin/reducer";
import application from "./Models/Application/reducer";

const rootReducers = combineReducers({
  admin,
  application,
});

const store = createStore(
  rootReducers,
  composeWithDevTools(applyMiddleware(thunk))
);
export default store;
