import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  TablePagination,
} from "@mui/material";

function RoyaltyTable({
  item,
  allpackages,
  columns,
  startDate,
  endDate,
  page,
  rowsPerPage,
  selectedWallet,
  handleChangePage,
  handleChangeRowsPerPage,
}) {
  const [incomeHistory, setIncomeHistory] = useState([]);

  useEffect(() => {
    if (item && allpackages && columns) {
      getPackageHistory(item, allpackages);
    }
  }, [item, allpackages, columns, startDate, endDate, selectedWallet]);

  const getPriceByIndex = (index, prices) => {
    const priceObject = prices.find((p) => p.royaltyIndex === index);
    return priceObject.dailyIncome;
  };

  const getPackageHistory = async (currentPackage, allpackages) => {
    const contractStartTimeStamp = "1711843200";
    const packageStartTimestamp = currentPackage.startingTimestamp;
    const currentTimestamp = `${Date.now() / 1000}`;

    const requiredStartTimestamp =
      contractStartTimeStamp > packageStartTimestamp
        ? contractStartTimeStamp
        : packageStartTimestamp;

    const requiredEndTimestamp =
      currentTimestamp > currentPackage.endingTimestamp
        ? currentPackage.endingTimestamp
        : currentTimestamp;

    const timestampDifference =
      requiredEndTimestamp > requiredStartTimestamp
        ? requiredEndTimestamp - requiredStartTimestamp
        : 0;

    const phaseIncome = getPriceByIndex(
      currentPackage.royaltyIndex,
      allpackages
    );

    let nonWorkingHistory = [];
    if (timestampDifference > 0) {
      const period = timestampDifference / (24 * 60 * 60);
      for (let index = 0; index < period - 1; index++) {
        nonWorkingHistory.push({
          "s.no": nonWorkingHistory.length,
          amount: `${phaseIncome / 2e18} USDT`,
          date: new Date(
            (Math.floor(requiredStartTimestamp) + (index + 2) * 24 * 60 * 60) *
              1000
          ).toLocaleDateString(),
          time: "12:00 am",
          wallet: "Naka wallet",
        });

        nonWorkingHistory.push({
          "s.no": nonWorkingHistory.length,
          amount: `${phaseIncome / 2e18} USDT`,
          date: new Date(
            (Math.floor(requiredStartTimestamp) + (index + 2) * 24 * 60 * 60) *
              1000
          ).toLocaleDateString(),
          time: "12:00 am",
          wallet: "Spot wallet",
        });
      }
    }

    setIncomeHistory(nonWorkingHistory);
  };

  const isCurrentDate = (date) => {
    const currentDate = new Date();
    const checkDate = new Date(date);
    return (
      checkDate.getUTCFullYear() === currentDate.getUTCFullYear() &&
      checkDate.getUTCMonth() === currentDate.getUTCMonth() &&
      checkDate.getUTCDate() === currentDate.getUTCDate()
    );
  };

  const filteredRows = incomeHistory.filter((row) => {
    const rowDate = new Date(row.date);
    const isDateInRange =
      !startDate || !endDate || (rowDate >= startDate && rowDate <= endDate);
    const isWalletMatch = !selectedWallet || row.wallet === selectedWallet;
    return isDateInRange && isWalletMatch && !isCurrentDate(row.date);
  });

  const slicedRows = filteredRows
    .reverse()
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <React.Fragment>
      <TableContainer sx={{ maxHeight: 500 }}>
        <Table stickyHeader aria-label="sticky table" sx={{ width: "100%" }}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    color: "White",
                    backgroundColor: "#12b6b3",
                    borderBottom: "solid 0px rgba(18, 182, 179, 0.4)",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {slicedRows.map((row, index) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ color: "white" }}
                    >
                      {column.id === "name" ? index + 1 : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="table-pagination">
        <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ color: "white" }}
        />
      </div>
    </React.Fragment>
  );
}

export default RoyaltyTable;
