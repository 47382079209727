import React from "react";
import { Route, Redirect } from "react-router-dom";
import { DashboardLayout } from "../layouts";
import { useAccount } from "wagmi";
import { injectModels } from "../Redux/injectModels";

const AuthenticRoute = ({ component: Component, ...rest }) => {
  const { address } = useAccount({
    onConnect({ address, connector, isReconnected }) {},
    onDisconnect() {
      localStorage.clear();
      window.location.reload();
    },
  });

  const joined = localStorage.getItem("isjoined");

  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <React.Fragment>
            {localStorage.getItem("isjoined") === "true" && address ? (
              <DashboardLayout {...props}>
                <Component {...props} />
              </DashboardLayout>
            ) : (
              <Redirect to="/" />
            )}
          </React.Fragment>
        );
      }}
    />
  );
};

export default injectModels(["application"])(AuthenticRoute);
