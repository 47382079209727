import React, { useRef, useState } from "react";
import DashboardHeader from "../components/Dashboard/DashboardHeader";
import DashboardFooter from "../components/Dashboard/DashboardFooter";
import Sidebar from "../components/Dashboard/Sidebar";

const DashboardLayout = (props) => {
  const isMobile = window.innerWidth <= 768;
  const [isOpen, setIsOpen] = useState(isMobile === false ? true : false);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const toggleSidebar = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };
  return (
    <React.Fragment>
      {isMobile === true && (
        <section
          className={`wrapper-large ${
            windowSize.current[0] > windowSize.current[1]
              ? isOpen
                ? "true"
                : "active"
              : !isOpen
              ? "true"
              : "active"
          }`}
        >
          <div className="left-part">
            <Sidebar handleClick={toggleSidebar} isOpenState={isOpen} />
          </div>
          <div className="right-part">
            <DashboardHeader handleClick={toggleSidebar} />

            <div className="inner-das">{props.children}</div>
            <DashboardFooter />
          </div>
        </section>
      )}
      {isMobile === false && (
        <section
          className={`wrapper-large ${
            windowSize.current[0] > windowSize.current[1]
              ? !isOpen
                ? "true"
                : "active"
              : isOpen
              ? "true"
              : "active"
          }`}
        >
          <div className="left-part">
            <Sidebar handleClick={toggleSidebar} isOpenState={isOpen} />
          </div>
          <div className="right-part">
            <DashboardHeader handleClick={toggleSidebar} />
            <div className="inner-das">{props.children}</div>
            <DashboardFooter />
          </div>
        </section>
      )}
    </React.Fragment>
  );
};

export default DashboardLayout;
