import React, { useState, useEffect } from "react";
import Web3 from "web3";

import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  TablePagination,
} from "@mui/material";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { createTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { useAccount } from "wagmi";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import * as CONTRACTS from "../../Contract";
import { injectModels } from "../../Redux/injectModels";

function BuisnessDetail(props) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [allPackageList, setAllPackagesList] = useState([]);
  const [investment, setInvestment] = useState("");
  const [tokenSymbol, setTokenSymbol] = useState("");
  const [activePackagePrice, setActivePackagePrice] = useState("");
  const [packagesIndex, setPackagesIndex] = useState("");
  const [selectedUserAddress, setSelectedUserAddress] = useState("");
  const [web3Instance, setWeb3Instance] = useState("");
  const [prices, setPrices] = useState([]);
  const [joiningDate, setJoiningDate] = useState("");

  const ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;
  const BLOCKCHAIN_NETWORK = process.env.REACT_APP_BLOCKCHAIN_NETWORK;

  const { address } = useAccount({
    onConnect() {},
    onDisconnect() {
      window.location.reload();
    },
  });

  const userData = props.data || [];

  const columns = [
    { id: "name", label: "S.No", minWidth: 50, align: "center" },
    {
      id: "userAddress",
      label: "Wallet Address",
      minWidth: 170,
      align: "center",
    },
    { id: "uplineID", label: "Upline Address", minWidth: 170, align: "center" },
    {
      id: "joiningTimestamp",
      label: "Join Time",
      minWidth: 170,
      align: "center",
    },
    { id: "action", label: "Action", minWidth: 50, align: "center" },
  ];

  const theme = createTheme({
    palette: {
      primary: {
        main: "#12b6b3",
      },
      text: {
        primary: "#12b6b3",
      },
    },
  });

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(4),
    },
    "& .MuiInputBase-input": {
      borderRadius: "6px",
      position: "relative",
      backgroundColor: "var(--card-bg)",
      border: "solid 2px rgba(18, 182, 179, 0.4)",
      color: "#fff",
      align: "center",
      padding: "10px 26px 10px 10px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      fontFamily: "poppins",
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }));

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
        style={{ width: "100%", height: 300 }}
      >
        {value === index && (
          <Box sx={{ p: 3, width: "100%" }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleStartDateChange = (date) => {
    setStartDate(date);
    filterUserData(date, endDate);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    filterUserData(startDate, date);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  const filterUserData = (start, end) => {
    const filtered = userData.filter((user) => {
      const timestamp = new Date(user.joiningTimestamp * 1000);
      return (!start || timestamp >= start) && (!end || timestamp <= end);
    });
    setFilteredData(filtered);
    setPage(0);
  };

  const convertTimestampToDateTime = (timestamp) => {
    const date = new Date(timestamp * 1000);

    const year = date.getUTCFullYear();
    const month = `0${date.getUTCMonth() + 1}`.slice(-2);
    const day = `0${date.getUTCDate()}`.slice(-2);
    let hours = date.getUTCHours();
    const minutes = `0${date.getUTCMinutes()}`.slice(-2);
    const seconds = `0${date.getUTCSeconds()}`.slice(-2);

    let period = "AM";
    if (hours >= 12) {
      period = "PM";
      hours -= 12;
    }
    if (hours === 0) {
      hours = 12;
    }

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${period}`;

    return formattedDateTime;
  };

  const getPackageJoiningDetailsList = async () => {
    try {
      const provider = window.ethereum
        ? window.ethereum
        : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);

      const web3 = new Web3(provider);
      setWeb3Instance(web3);
      const contract = new web3.eth.Contract(CONTRACTS.Nakamoto.abi, ADDRESS);

      const packageJoiningDetails = await contract.methods
        .getPackagesWithJoiningDetails(selectedUserAddress)
        .call();

      return packageJoiningDetails;
    } catch (err) {
      console.log(err, "err");
      props.application.setLoading(false);
      // toast.error("Something went wrong");
    }
  };

  const fetchPackageDetails = async () => {
    try {
      const pd = await getPackageJoiningDetailsList();
      const newPrices = pd[0].map((e) => ({
        packageIndex: e.packageIndex,
        price: e.price,
      }));
      setPrices(newPrices);
    } catch (error) {
      console.error("Error fetching package details:", error);
    }
  };

  useEffect(() => {
    fetchPackageDetails();
  }, [selectedUserAddress]);

  const getPriceByIndex = (index) => {
    const priceObject = prices.find((p) => p.packageIndex === index);
    return priceObject
      ? web3Instance.utils.fromWei(priceObject.price, "ether")
      : 0;
  };

  const highlightedAndSortedData = (
    startDate && endDate ? filteredData : userData
  ).sort((a, b) => {
    if (a.uplineID === address && b.uplineID !== address) {
      return -1;
    } else if (a.uplineID !== address && b.uplineID === address) {
      return 1;
    } else {
      return 0;
    }
  });
  const slicedRows = highlightedAndSortedData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const getPackageJoiningDetails = async (pIndex, address) => {
    props.application.setLoading(true);
    try {
      const provider = window.ethereum
        ? window.ethereum
        : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);

      const web3 = new Web3(provider);
      const contract = new web3.eth.Contract(CONTRACTS.Nakamoto.abi, ADDRESS);

      const packageJoiningDetails = await contract.methods
        .getPackagesWithJoiningDetails(address)
        .call();
      setTokenSymbol(packageJoiningDetails.joiningTokenSymbol_);
      const packages = await packageJoiningDetails.packages_;

      if (packages && packages.length > 0) {
        let selectedPackage;
        packages.forEach((e) => {
          if (e.packageIndex === pIndex) {
            selectedPackage = e;
          }
        });

        if (selectedPackage) {
          const packageprice = web3.utils.fromWei(
            selectedPackage.price.toString(),
            "ether"
          );
          setActivePackagePrice(packageprice);
          props.application.setLoading(false);
        } else {
          console.log("Invalid packages index");
          props.application.setLoading(false);
        }
      } else {
        console.log("packages not fetched");
        props.application.setLoading(false);
      }
    } catch (err) {
      console.log(err, "err");
      props.application.setLoading(false);
    }
  };

  const handleShowModal = async (user) => {
    setSelectedUserAddress(user.userAddress);
    setShowModal(true);
    try {
      const provider =
        window.ethereum && window.ethereum
          ? window.ethereum
          : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);

      const web3 = new Web3(provider);
      const contract = new web3.eth.Contract(CONTRACTS.Nakamoto.abi, ADDRESS);
      const userDetails = await contract.methods
        .getUser(user.userAddress)
        .call();
      const packages = web3.utils.fromWei(userDetails.totalInvestment, "ether");
      setInvestment(packages);
      setJoiningDate(userDetails && userDetails.timestamp);
      let pIndex;
      setAllPackagesList(userDetails.joinedPackages);
      userDetails.joinedPackages.forEach((e) => {
        if (e[0] === true) {
          pIndex = e[2];
        }
      });

      setPackagesIndex(pIndex);

      await getPackageJoiningDetails(pIndex, user.userAddress);
    } catch (err) {}
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="accordion-body">
        <div className="row g-2 mb-2 justify-content-center">
          <div className="nonworking">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker", "DatePicker"]}>
                <DemoItem label="Start date" sx={{ color: "#fff" }}>
                  <DatePicker
                    sx={{
                      width: "100%",
                      bgcolor: "var(--card-bg)",
                      border: "solid 2px rgba(18, 182, 179, 0.4)",
                      borderRadius: "6px",
                      color: "white",
                    }}
                    slotProps={{
                      actionBar: { actions: ["clear"] },
                    }}
                    value={startDate}
                    onChange={handleStartDateChange}
                  />
                </DemoItem>
                <DemoItem label="End date">
                  <DatePicker
                    sx={{
                      width: "100%",
                      bgcolor: "var(--card-bg)",
                      border: "solid 2px rgba(18, 182, 179, 0.4)",
                      borderRadius: "6px",
                      color: "white",
                    }}
                    slotProps={{
                      actionBar: { actions: ["clear"] },
                    }}
                    value={endDate}
                    onChange={handleEndDateChange}
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
          </div>
          <Paper
            sx={{
              width: "100%",
              overflow: "hidden",
              height: "100%",
              bgcolor: "var(--card-bg)",
              border: "solid 1px rgba(18, 182, 179, 0.4);",
              borderRadius: "5px",
            }}
          >
            <TableContainer sx={{ maxHeight: 500 }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{ width: "100%" }}
              >
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          color: "White",
                          backgroundColor: "#12b6b3",
                          borderBottom: "solid 0px rgba(18, 182, 179, 0.4)",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {slicedRows.map((row, index) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      style={{
                        backgroundColor:
                          row.uplineID === address ? "#2C7170 " : "inherit",
                      }}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          // <TableCell
                          //   key={column.id}
                          //   align={column.align}
                          //   style={{ color: "white" }}
                          // >
                          //   {/* {column.id === "name" ? index + 1 : value} */}
                          //   {column.id === "name"
                          //     ? index + 1
                          //     : column.id === "joiningTimestamp"
                          //     ? convertTimestampToDateTime(value)
                          //     : value}
                          // </TableCell>
                          <TableCell align="center" style={{ color: "white" }}>
                            {column.id === "action" ? (
                              <FontAwesomeIcon
                                icon={faEye}
                                onClick={() => handleShowModal(row)}
                                style={{ cursor: "pointer" }}
                              />
                            ) : column.id === "name" ? (
                              index + 1
                            ) : column.id === "joiningTimestamp" ? (
                              convertTimestampToDateTime(value)
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TableCell
              style={{
                color: "white",
                borderBottom: "solid 0px rgba(18, 182, 179, 0.4)",
              }}
            >
              <div className="table-pagination">
                <TablePagination
                  rowsPerPageOptions={[5, 10]}
                  component="div"
                  count={highlightedAndSortedData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{ color: "white" }}
                />
              </div>
            </TableCell>
          </Paper>
        </div>
      </div>

      <Modal backdrop="static" show={showModal} centered>
        <div className="networkexpand">
          <Modal.Body>
            <div className="my-custom-class">
              <div className="user-business-details">
                <div onClick={handleCloseModal} className="cros-icon">
                  {" "}
                  <i class="fa-solid fa-xmark"></i>{" "}
                </div>
                <div className="busines-user-img"></div>

                <div className="bns-details">
                  <ul>
                    <li>
                      <p>Joining Date :</p>
                      <p> {convertTimestampToDateTime(joiningDate)}</p>{" "}
                    </li>
                    <li>
                      <p>Total Investment :</p>{" "}
                      <p>
                        {investment} {tokenSymbol}
                      </p>{" "}
                    </li>
                    <div className="tbing-transaction">
                      <h6>Top Up history </h6>
                      <div className="table-responsive">
                        <table>
                          <thead>
                            <tr>
                              <th scope="col">S.No.</th>
                              <th scope="col">Status</th>
                              <th scope="col">Amount</th>
                              <th scope="col">Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {allPackageList && allPackageList.length > 0 ? (
                              <>
                                {allPackageList.map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>
                                        {item.isActive === true
                                          ? "Active"
                                          : "Inactive"}
                                      </td>
                                      <td>
                                        {getPriceByIndex(item.packageIndex)}{" "}
                                        {tokenSymbol}
                                      </td>
                                      <td>
                                        {convertTimestampToDateTime(
                                          item.timestamp
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            ) : (
                              <div>
                                <span>No Top-Up history...</span>
                              </div>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>{" "}
      </Modal>
    </>
  );
}

export default injectModels(["admin", "application"])(BuisnessDetail);
