import Lottie from "lottie-react";
import React, { useEffect } from "react";
import comingsoonAnimation from "../animation/progress_bar.json";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const PleaseWait = () => {
    const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleRedirect = () => {
    history.push("/");
  };
  return (
    <section className="terms-condtion progress-new">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="terms-content">
              <h2>
                {" "}
                <span>Under maintenance</span>
              </h2>
              <Lottie
                style={{ height: 250, marginTop: 50 }}
                animationData={comingsoonAnimation}
                loop={true}
              />
              <h3 style={{ textAlign: "center", color: "white" }}>
                This Functionlity is in under maintenance.
              </h3>
              {/* <div className="yyyui">
                <div className="progress" style={{ width: "40%" }}>
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated bg-info"
                    role="progressbar"
                    aria-valuenow={79}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{ width: "79%" }}
                  >
                    79%
                  </div>
                </div>
              </div> */}
              
              <p>
                {" "}
                We will be back in no time
              </p> 
              <div className="pleasegohome">
                <button onClick={handleRedirect}>Go to Home page</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PleaseWait;

