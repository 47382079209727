import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const Error = () => {
  const history = useHistory();

  const handleRedirect = () => {
    history.push("/");
  };
  return (
    <React.Fragment>
      <section className="error-page">
        <div className="container">
          <div className="not-found">
            <h1>404 Page Not Found</h1>
            <p>Oops! The page you're looking for could not be found.</p>
            <p>Please check the URL or navigate back to the homepage.</p>
            <button onClick={handleRedirect}>Go to Home page</button>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Error;
