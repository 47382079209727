import React, { useState, useEffect } from "react";
import { injectModels } from "../../Redux/injectModels";
import { useHistory } from "react-router-dom";

const FaqHome = (props) => {
  const [faq, setFaq] = useState([]);
  const history = useHistory();

  useEffect(() => {
    getFaqData();
  }, []);

  const getFaqData = async () => {
    try {
      const response = await props.admin.getFaq();
      if (response.Faqs) {
        setFaq(response.Faqs.reverse());
      } else {
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClick = () => {
    history.push("/faq");
  };
  return (
    <>
      <section className="faq">
        <div className="bg-heading-faq">
          <h2>
            <span> FAQs </span>
          </h2>
        </div>
        <div className="container">
          <div className="row">
            <div className="accordion" id="accordionExample">
              {faq.slice(0, 3).map((item, index) => {
                return (
                  <div className="accordion-item" key={index}>
                    <h2 className="accordion-header" id={`heading${index}`}>
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${index}`}
                        aria-expanded="false"
                        aria-controls={`collapse${index}`}
                      >
                        {item.question}
                      </button>
                    </h2>
                    <div
                      id={`collapse${index}`}
                      className="accordion-collapse collapse"
                      aria-labelledby={`heading${index}`}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <strong>{item.answer}</strong>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="viewmore-btn">
          <button onClick={handleClick}>Show More</button>
        </div>
      </section>
    </>
  );
};

export default injectModels(["admin", "application"])(FaqHome);
