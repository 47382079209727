import store from "../../store";
import * as ACTIONS from "./action";

const obj = {
  uploadImage: (data) => store.dispatch(ACTIONS.uploadImage(data)),
  getUserNotification: () => store.dispatch(ACTIONS.getUserNotification()),
  getUserNotificationDetail: (address) =>
    store.dispatch(ACTIONS.getUserNotificationDetail(address)),
  UserNotificationUpdates: (address, id) =>
    store.dispatch(ACTIONS.UserNotificationUpdates(address, id)),
  getFaq: () => store.dispatch(ACTIONS.getFaq()),
  addContactUs: (data) => store.dispatch(ACTIONS.addContactUs(data)),
  getAboutUs: () => store.dispatch(ACTIONS.getAboutUs()),
  getTermsConditions: () => store.dispatch(ACTIONS.getTermsConditions()),
  getPrivacyPolicy: () => store.dispatch(ACTIONS.getPrivacyPolicy()),
  updateTotalJoin: (totalJoin) =>
    store.dispatch(ACTIONS.updateTotalJoin(totalJoin)),
  updateProfitGenerated: (profitGenerated) =>
    store.dispatch(ACTIONS.updateProfitGenerated(profitGenerated)),
  updateUserDetails: (data) => store.dispatch(ACTIONS.updateUserDetails(data)),
  getUserProfileDetails: (data) =>
    store.dispatch(ACTIONS.getUserProfileDetails(data)),
  addUserDetails: (data) => store.dispatch(ACTIONS.addUserDetails(data)),
  getReferalWalletAddress: (data) =>
    store.dispatch(ACTIONS.getReferalWalletAddress(data)),
  getAllUsers: () => store.dispatch(ACTIONS.getAllUsers()),
};
export default obj;
