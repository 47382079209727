import React, { useState, useEffect } from "react";
import {  Link } from "react-router-dom";
import { injectModels } from "../../Redux/injectModels";
import IconTint from "react-icon-tint";

const Banner = (props) => {
  
  return (
    <>
      <section className="banner">
        <div className="container">
          <div className="row">
            <div className="col-md-6 justify-content-center d-flex  align-items-center">
              <div className="banner-heading">
                <h1>Unlocking the Potential of Decentralized Finance!</h1>
                <Link
                  to={{
                    pathname:
                      "https://www.quillaudits.com/leaderboard/naka-token",
                  }}
                  target="_blank"
                >
                  <IconTint
                    src="/assets/img/quillAudits1.png"
                    style={{
                      height: "80px",
                      borderBottomRightRadius: 60,
                      borderTopRightRadius: 60,
                    }}
                    color="transparent"
                  />
                </Link>
              </div>
            </div>
            <div className="col-md-6">
              <div className="banner-logo">
                <img
                  src="/assets/img/BannerLogo.svg"
                  className="img-fluid"
                  alt="true"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default injectModels(["admin", "application"])(Banner);
