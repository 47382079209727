import React from "react";
import {
  useParams,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
const DashboardHeader = (props) => {
  const location = useLocation();

  const { id } = useParams();
  return (
    <>
      <section className="das-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="toggle-btn">
                <div className="menu-burger">
                  <a onClick={props.handleClick}>
                    <img src="/assets/img/menu-bar.svg" alt="menu" />
                  </a>
                  {(() => {
                    switch (location.pathname) {
                      case "/dashboard/wallet":
                        return <h6>Wallet</h6>;
                      case "/dashboard/timeline":
                        return <h6>Timeline</h6>;
                      case "/dashboard":
                        return <h6>Profile</h6>;
                      case "/dashboard/timeline":
                        return <h6>Timeline</h6>;
                      case "/dashboard/notification":
                        return <h6>Notifications</h6>;
                      case "/dashboard/network":
                        return <h6>Network</h6>;
                      case "/dashboard/edit-profile":
                        return <h6>Edit Profile</h6>;
                      case "/dashboard/tutorial":
                        return <h6>Tutorial</h6>;
                      default:
                        return <h6>Dashboard</h6>;
                    }
                  })()}
                </div>
                <div className="tocken-btn-das">
                  <a
                    href="https://app.uniswap.org/swap?exactField=input&exactAmount=1&inputCurrency=0xc2132D05D31c914a87C6611C10748AEb04B58e8F&outputCurrency=0x5dd1cbf142F4B896D18aF835C1734363b0d50fB0&chain=polygon"
                    target="_blank"
                  >
                    <button>
                      {" "}
                      <img
                        src="/assets/img/purchse-token.svg"
                        className="img-fluid"
                        alt=""
                      />{" "}
                      <span> Purchase Naka Token </span>{" "}
                    </button>
                  </a>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DashboardHeader;
