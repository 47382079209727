import React from "react";
import CountUp from "react-countup";

export default function Counter({ number, title }) {
  return (
    <div className="number">
      <CountUp className="counter" duration={5} end={number} />
    </div>
  );
}
