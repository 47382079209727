import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Tutorial = () => {
const history= useHistory();
  
  return (
    <>
      <section className="network">
        <div className="container-fluid">
          <div className="row ">
            <div className="col-md-8">
              <div className="form-group mb-3">
                <div
                  className="dropzone dropzone-single mb-3 dz-clickable"
                  data-toggle="dropzone"
                  data-dropzone-url="http://"
                >
                  <div className="dz-default dz-message">
                    
                    <span onClick={()=>{history.push("/nakamoto-school")}}>Go to the tutorial</span>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Tutorial;
