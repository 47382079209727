import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { injectModels } from "../../Redux/injectModels";
import { useAccount } from "wagmi";
import * as CONTRACTS from "../../Contract";
import Web3 from "web3";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { Modal } from "react-bootstrap";
import { useContractWrite } from "wagmi";
const targetDate = new Date("2024-09-24T05:30:00");

const Dashboard = (props) => {
  const history = useHistory();
  const [timeStamp, setTimeStamp] = useState("");
  const [userData, setUserData] = useState("");
  const [uplineId, setUplineId] = useState("");
  const [isJoined, setIsJoined] = useState();
  const [rank, setRank] = useState(0);
  const [packagesIndex, setPackagesIndex] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [sortedAddress, setSortedAddress] = useState("");
  const [availableNakaToken, setAvailableNakaToken] = useState("");
  const [allPackageList, setAllpackagesList] = useState("");
  const [isProfileUpdated, setIsProfileUpdate] = useState(false);
  const [image, setImage] = useState("");
  const [joiningTokenAddress, setJoiningTokenAddress] = useState("");
  const [showTopUpModal, setShowTopUpModal] = useState(false);
  const [showTopUpModal2, setShowTopUpModal2] = useState(false);
  const [showTopUpModal3, setShowTopUpModal3] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState([true, -1, -1, -1]);
  const [activepackageTimeStamp, setActivepackageTimeStamp] = useState("");
  const [allowance, setAllowance] = useState("");
  const [packageList, setPackageList] = useState([]);
  const [tokenSymbol, setTokenSymbol] = useState("");
  const [balance, setBalance] = useState("");
  const [web3Instance, setweb3Instance] = useState("");
  const [currentFunctionIndex, setCurrentFunctionIndex] = useState(0);
  const [walletUpgrade, setWalletUpgrade] = useState(false);
  const [walletSelect, setWalletSelect] = useState(false);
  const [investment, setInvestment] = useState("");
  const [decimals, setDecimals] = useState("");
  const [avialableSpotBalnace, setAvialableSpotBalnace] = useState("");
  const [isActive, setIsActive] = useState("");

  const [packageJoiningTokenSymbol, setPackageJoiningTokenSymbol] =
    useState("");
  const [prices, setPrices] = useState([]);
  const [activeButton, setActiveButton] = useState(null);
  const [timeLeft, setTimeLeft] = useState(null);

  const ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;
  const BLOCKCHAIN_NETWORK = process.env.REACT_APP_BLOCKCHAIN_NETWORK;

  const { address, isConnecting, isDisconnected } = useAccount({
    onConnect({ address, connector, isReconnected }) {
      const firstThree = address.slice(0, 3);
      const lastSix = address.slice(-6);
      const sortedAdd = firstThree + "...." + lastSix;

      setSortedAddress(sortedAdd);
      setWalletAddress(address);
    },
    onDisconnect() {
      window.location.reload();
    },
  });

  const isActiveCheck = localStorage.getItem("isActive");

  const handleClick = (buttonId) => {
    setActiveButton(buttonId);
  };

  const { write } = useContractWrite({
    onError(e) {
      switch (currentFunctionIndex) {
        case 1:
          setCurrentFunctionIndex(0);
          toast.error(`An error happened:${e.details}`);
          props.application.setLoading(false);
          break;
        case 2:
          setCurrentFunctionIndex(0);
          toast.error(`An error happened:${e.details}`);
          props.application.setLoading(false);
          break;
        case 3:
          setCurrentFunctionIndex(0);
          toast.error(`An error happened:${e.details}`);
          props.application.setLoading(false);
          break;
        default:
          setCurrentFunctionIndex(0);
      }
    },

    onSettled(data, error) {
      switch (currentFunctionIndex) {
        case 1:
          setCurrentFunctionIndex(0);
          if (error) {
            props.application.setLoading(false);
            toast.dismiss();
            toast.error(`Error: ${error.error.message}`);
            setCurrentFunctionIndex(0);
          } else {
            props.application.setLoading(false);
            toast.dismiss();
            toast.success("Retopup Successfull!");
            setCurrentFunctionIndex(0);
          }
          break;
        case 2:
          setCurrentFunctionIndex(0);
          if (error) {
            toast.error(error && error.details, "error");
          } else if (data.hash !== undefined) {
            props.application.setLoading(true);
            setCurrentFunctionIndex(1);
            const fromNakaWallet = false;
            write({
              args: [fromNakaWallet],
              address: ADDRESS,
              abi: CONTRACTS.Nakamoto.abi,
              functionName: "reTopUp",
            });
          }
          break;

        case 3:
          setCurrentFunctionIndex(0);
          if (error) {
            props.application.setLoading(false);
            toast.dismiss();
            toast.error(`Error: ${error.error.message}`);
            setCurrentFunctionIndex(0);
          } else {
            handleCloseTopUpModal();
            props.application.setLoading(false);
            toast.success("Transaction sent successfully");
            setCurrentFunctionIndex(0);
          }
          break;

        default:
          setCurrentFunctionIndex(0);
          if (error) {
          } else {
          }
      }
    },
  });

  const handleOpenTopUpModal = async () => {
    setShowTopUpModal(true);
  };

  const handleOpenTopUpModal2 = async () => {
    setShowTopUpModal2(true);
  };

  const handleCloseTopUpModal = () => {
    setShowTopUpModal(false);
    setWalletSelect(false);
    setActiveButton(null);
    setSelectedPlan([true, -1, -1, -1]);
  };

  const handleCloseTopUpModal2 = () => {
    setShowTopUpModal2(false);
  };

  const handleCloseTopUpModal3 = () => {
    setShowTopUpModal3(false);
  };

  const handleCopyClick = () => {
    toast.dismiss();
    toast.success("Copied");
  };

  const getUserDetails = async () => {
    try {
      if (address) {
        props.application.setLoading(true);
        const provider = window.ethereum
          ? window.ethereum
          : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);
        const web3 = new Web3(provider);
        const contract = new web3.eth.Contract(CONTRACTS.Nakamoto.abi, ADDRESS);
        const receipt = await contract.methods.getUser(address).call();

        const packages = web3.utils.fromWei(receipt.totalInvestment, "ether");
        setInvestment(packages);
        let pIndex = [];
        receipt.joinedPackages.forEach((e) => {
          setActivepackageTimeStamp(e.startingTimestamp);
          if (e[0] === true) {
            pIndex.push(e[2]);
          }
        });
        setAvialableSpotBalnace(
          web3.utils.fromWei(receipt.availableSpotWalletIncome, "ether")
        );

        setPackagesIndex(pIndex);
        setAllpackagesList(receipt.joinedPackages);

        setAvailableNakaToken(receipt.availableNakaWalletIncome);
        setTimeStamp(receipt.joiningTimestamp);
        setUplineId(receipt[5]);
        setIsJoined(receipt[0]);
        setRank(receipt[8]);
        let pd = await getPackageJoiningDetailsList();

        let totalInvestment = 0;
        pIndex.forEach((packageIndex) => {
          pd[0].forEach((e) => {
            if (e.packageIndex === packageIndex) {
              totalInvestment = Number(totalInvestment) + Number(e.price);
            }
          });
        });
        props.application.setLoading(false);

        if (isProfileUpdated === false) {
          const data = {
            fname: "Hello",
            lname: "User!",
            avatar:
              "https://api.nakatoken.io/images/8efd7c6199a0e0cba4428e774778f377.1707123104916.png",
            walletAddress: address,
            uplineAddress: receipt[5],
            joiningDate: receipt[3],
            isJoined: true,
            userId: address.slice(-7),
            uplineId: receipt[5].slice(-7),
          };
          try {
            await props.admin.addUserDetails(data);
          } catch (err) {
            console.log(err, "err");
          }
          await getData();
        }
      } else {
        console.log("No adddress found");
        props.application.setLoading(false);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };

  const fetchPackageDetails = async () => {
    try {
      const pd = await getPackageJoiningDetailsList();
      const newPrices = pd[0].map((e) => ({
        packageIndex: e.packageIndex,
        price: e.price,
      }));
      setPrices(newPrices);
    } catch (error) {
      console.error("Error fetching package details:", error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const timeDiff = targetDate - now;

      if (timeDiff <= 0) {
        // If the time is past the target date, stop the countdown
        clearInterval(interval);
        setTimeLeft(null); // No countdown left
      } else {
        // Calculate days, hours, minutes, and seconds left
        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((timeDiff / 1000 / 60) % 60);
        const seconds = Math.floor((timeDiff / 1000) % 60);

        setTimeLeft(`${days}d ${hours}h ${minutes}m ${seconds}s`);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const currentDate = new Date();

  useEffect(() => {
    fetchPackageDetails();
    const isActiveCheck = localStorage.getItem("isActive");
    if (isActiveCheck === "false") {
      setIsActive(false);
    }
  }, []);

  const getPriceByIndex = (index) => {
    const priceObject = prices.find((p) => p.packageIndex === index);

    return priceObject
      ? web3Instance.utils.fromWei(priceObject.price, "ether")
      : 0;
  };

  const getPackageJoiningDetailsList = async () => {
    try {
      const provider = window.ethereum
        ? window.ethereum
        : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);

      const web3 = new Web3(provider);
      setweb3Instance(web3);
      const contract = new web3.eth.Contract(CONTRACTS.Nakamoto.abi, ADDRESS);

      const packageJoiningDetails = await contract.methods
        .getPackagesWithJoiningDetails(address)
        .call();
      setPackageJoiningTokenSymbol(packageJoiningDetails.joiningTokenSymbol_);
      setJoiningTokenAddress(packageJoiningDetails.joiningTokenAddress_);
      setDecimals(packageJoiningDetails.joiningTokenDecimals_);
      return packageJoiningDetails;
    } catch (err) {
      console.log(err, "err");
      props.application.setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const handleRetopUpAndUpgrade = async (packageIndex, fromNakaWallet) => {
    try {
      props.application.setLoading(true);

      const provider = window.ethereum
        ? window.ethereum
        : new Web3.providers.HttpProvider(
            "https://polygon-mumbai.infura.io/v3/b94a21d7655b424e88f7700f411afb97"
          );
      const web3 = new Web3(provider);

      const contract = new web3.eth.Contract(
        CONTRACTS.ERC20.abi,
        joiningTokenAddress
      );

      const pd = await getPackageJoiningDetailsList();
      let requiredAmount = 0;

      pd[0].forEach((e) => {
        if (e.packageIndex === packageIndex.toString()) {
          requiredAmount =
            Number(web3.utils.fromWei(e.fee.toString(), "ether")) +
            Number(web3.utils.fromWei(e.price.toString(), "ether"));
        }
      });
      if (fromNakaWallet) {
        requiredAmount /= 2;
        const availableNakaTokenWei =
          availableNakaToken && web3.utils.fromWei(availableNakaToken, "ether");
        const hasSufficientBalance =
          Number(availableNakaTokenWei) >= Number(requiredAmount);
        if (!hasSufficientBalance) {
          throw new Error("Insufficient balance");
        }
      }

      if (
        requiredAmount.toString() > Number(balance / Math.pow(10, decimals))
      ) {
        throw new Error("Insufficient balance");
      } else {
        const tx1 = contract.methods.approve(
          ADDRESS,
          web3.utils.toWei(requiredAmount.toString(), "ether")
        );
        const gas1 = await tx1.estimateGas({ from: address });
        const gasPrice1 = await web3.eth.getGasPrice();
        const tx1Receipt = await tx1.send({
          from: address,
          gas: gas1 * 2,
          gasPrice: gasPrice1 * 2,
        });

        const contract2 = new web3.eth.Contract(
          CONTRACTS.Nakamoto.abi,
          ADDRESS
        );

        const getPercentageDifference = await contract2.methods
          .getPercentageDifference(address, uplineId)
          .call();
        const tx2 = contract2.methods.upgrade(
          fromNakaWallet,
          packageIndex,
          getPercentageDifference && getPercentageDifference.userAddressList,
          getPercentageDifference && getPercentageDifference.percentageList
        );
        const gas2 = await tx2.estimateGas({ from: address });
        const gasPrice2 = await web3.eth.getGasPrice();

        const tx2Receipt = await tx2.send({
          from: address,
          gas: gas2 * 2,
          gasPrice: gasPrice2 * 2,
        });

        props.application.setLoading(false);
        toast.success("Transaction sent successfully");
        window.location.reload();
      }
    } catch (err) {
      props.application.setLoading(false);
      if (err.code === 4001) {
        toast.error(`User rejected the transaction`);
      } else if (err.code === -32000) {
        toast.error(`Transaction reverted: ${err.message}`);
      } else if (err.message.includes("insufficient funds")) {
        toast.error(`Insufficient funds: ${err.message}`);
      } else if (err.message.includes("nonce too low")) {
        toast.error(`Nonce too low: ${err.message}`);
      } else if (err.message.includes("known transaction")) {
        toast.error(
          `Transaction already in the transaction pool: ${err.message}`
        );
      } else {
        // toast.error(`Error: ${err.message}`);
        toast.error(`Error caught: ${err}, Trying again`);
      }
    }
  };

  const handleSelectPlan = async (plan) => {
    setSelectedPlan(plan);
    localStorage.setItem(
      "upgradePlan",
      JSON.stringify({ plan, selected: true })
    );
  };

  const handleUpgrade = async () => {
    const packageDetails = await getPackageJoiningDetailsList();
    setPackageList(packageDetails && packageDetails[0]);
    setTokenSymbol(packageDetails && packageDetails[6]);
    setBalance(packageDetails && packageDetails[1]);

    handleOpenTopUpModal();
    // history.push("/under-maintenance")
  };

  const getData = async () => {
    props.application.setLoading(true);
    try {
      const res = await props.admin.getUserProfileDetails(address);

      if (res.data) {
        setUserData(res.data && res.data.data.user);
        setIsProfileUpdate(
          res.data.user && res.data.data.user.isProfileUpdated
        );
        setImage(res.data.data.user.avatar);

        props.application.setLoading(false);
      } else {
        props.application.setLoading(false);
      }
    } catch (error) {
      props.application.setLoading(false);
      console.log(error);
    }
  };

  const convertTimestampToDateTime = (timestamp) => {
    const date = new Date(timestamp * 1000);

    const year = date.getUTCFullYear();
    const month = `0${date.getUTCMonth() + 1}`.slice(-2);
    const day = `0${date.getUTCDate()}`.slice(-2);
    let hours = date.getUTCHours();
    const minutes = `0${date.getUTCMinutes()}`.slice(-2);
    const seconds = `0${date.getUTCSeconds()}`.slice(-2);

    let period = "AM";
    if (hours >= 12) {
      period = "PM";
      hours -= 12;
    }
    if (hours === 0) {
      hours = 12;
    }

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${period}`;

    return formattedDateTime;
  };

  const handleTopUp = () => {
    setShowTopUpModal(false);
    setShowTopUpModal2(false);
    setShowTopUpModal3(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getUserDetails();
      await getData();
      await getPackageJoiningDetailsList();
    };

    fetchData();
  }, [address]);

  const currentURL = window.location.href;
  const pathWithoutPrefix = currentURL.replace(/\/dashboard/, "");

  return (
    <>
      {isActive === false && (
        <div className="no-activerecords mb-3">
          <h6>
            Your account is no longer active please{" "}
            <Link className="contactlink" to="/contact">
              contact
            </Link>{" "}
            us.
          </h6>
        </div>
      )}
      <section
        className="user-profile"
        style={{
          backgroundImage: "url(assets/img/profile-banner-svg.png)",
        }}
      ></section>
      <section className="user-detail">
        <div className="user-detail-rtfy">
          <div className="edit-btn-profile">
            <div className="edit-profile">
              <img
                src={
                  image
                    ? image
                    : "https://api.nakatoken.io/images/8efd7c6199a0e0cba4428e774778f377.1707123104916.png"
                }
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="Paper-btn-user">
              <div className="user-name-mobile">
                <h3>
                  {userData ? (
                    <>
                      {userData.firstName} {userData.lastName}
                    </>
                  ) : (
                    <>Hello User!</>
                  )}
                </h3>

                <h6>
                  {rank === "No Ranks Achieved" ? (
                    <>No rank achieved yet</>
                  ) : (
                    <>Rank :- {rank}</>
                  )}
                </h6>
                <h5>
                  Address: &nbsp;{sortedAddress && <span>{sortedAddress}</span>}{" "}
                  &nbsp;{" "}
                  <span>
                    <CopyToClipboard
                      text={walletAddress}
                      onCopy={handleCopyClick}
                    >
                      <sub>
                        <i className="fa-regular fa-copy"></i>
                      </sub>
                    </CopyToClipboard>
                  </span>
                </h5>
              </div>
              <div className="network-btn">
                <h5>
                  My ID : &nbsp;
                  {userData.userId ? <span>{userData.userId}</span> : ""}
                  &nbsp;{" "}
                  <span>
                    <CopyToClipboard
                      text={userData.userId}
                      onCopy={handleCopyClick}
                    >
                      <sub>
                        <i className="fa-regular fa-copy"></i>
                      </sub>
                    </CopyToClipboard>
                  </span>
                </h5>
                <div className="vwef">
                  <button
                    onClick={() => {
                      history.push({
                        pathname: "/dashboard/edit-profile",
                        state: {
                          walletAddress: address,
                          uplineAddress: uplineId,
                          timeStamp: timeStamp,
                          isJoined: isJoined,
                        },
                      });
                    }}
                  >
                    {" "}
                    <i className="fa-regular fa-pen-to-square" />{" "}
                    <span>Edit Profile</span>
                  </button>
                  <button
                    onClick={() => {
                      history.push("/dashboard/network");
                    }}
                    className
                  >
                    <i className="fa-solid fa-users" />
                    <span> View Network </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="user-list-name">
            <div className="user-name">
              <h3>
                {userData ? (
                  <>
                    {userData.firstName} {userData.lastName}
                  </>
                ) : (
                  <>Hello User!</>
                )}
              </h3>

              <h6>
                {rank === "No Ranks Achieved" ? (
                  <>No rank achieved yet</>
                ) : (
                  <>Rank :- {rank}</>
                )}
              </h6>
              <h5>
                Address : &nbsp;{sortedAddress && <span>{sortedAddress}</span>}{" "}
                &nbsp;{" "}
                <span>
                  <CopyToClipboard
                    text={walletAddress}
                    onCopy={handleCopyClick}
                  >
                    <sub>
                      <i className="fa-regular fa-copy"></i>
                    </sub>
                  </CopyToClipboard>
                </span>
              </h5>
            </div>

            <div className="user-full-details">
              <div className="retopup">
                <div className="trtfy ">
                  <ul>
                    <li>
                      <span>Referral Link:</span>{" "}
                      <label>
                        <i>
                          {`${pathWithoutPrefix}/invite/${walletAddress.slice(
                            -7
                          )}`}{" "}
                        </i>
                        <CopyToClipboard
                          text={`${pathWithoutPrefix}/invite/${walletAddress.slice(
                            -7
                          )}`}
                          onCopy={handleCopyClick}
                        >
                          <sub>
                            <i className="fa-regular fa-copy"></i>
                          </sub>
                        </CopyToClipboard>
                      </label>
                    </li>
                    <li>
                      <span>Upline ID:</span>
                      <label>
                        <i>{uplineId ? uplineId.slice(-7) : "xxxxxxx"}</i>{" "}
                        <CopyToClipboard
                          text={uplineId.slice(-7)}
                          onCopy={handleCopyClick}
                        >
                          <sub>
                            <i className="fa-regular fa-copy"></i>
                          </sub>
                        </CopyToClipboard>
                      </label>
                    </li>
                    <li>
                      <span>Upline Address:</span>
                      <label>
                        <i>{uplineId ? uplineId : "xxxxxxxxxxxxxxxxxx"}</i>{" "}
                        <CopyToClipboard
                          text={uplineId}
                          onCopy={handleCopyClick}
                        >
                          <sub>
                            <i className="fa-regular fa-copy"></i>
                          </sub>
                        </CopyToClipboard>
                      </label>
                    </li>
                  </ul>
                </div>
                <div className="border-r-new"></div>
                <div className="trtfy ">
                  <ul>
                    <li>
                      <span>Date of Joining:</span>
                      <label>
                        <i>{convertTimestampToDateTime(timeStamp)}</i>
                      </label>
                    </li>

                    <li>
                      <span>Active Package detail:</span>
                      <label>
                        <i>
                          {investment}
                          {"  "}
                          {packageJoiningTokenSymbol}
                        </i>
                      </label>
                    </li>
                    <li>
                      <span>Top-Up:</span>
                      <label>
                        <button
                          className="wallet-balance"
                          onClick={handleTopUp}
                        >
                          History
                        </button>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="back-top">
                <h6 onClick={handleUpgrade}>
                  <a>Upgrade</a>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        show={showTopUpModal}
        onHide={handleCloseTopUpModal}
        backdrop="static"
      >
        <div className="select-plan-rtfy">
          <Modal.Header closeButton>
            <Modal.Title>Select a Upgrade Plan</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="select-plan">
              <div className="conn-change">
                <div className="gfrty">
                  {" "}
                  <span>Select Plan </span>
                </div>
              </div>
              <ul>
                {packageList
                  .filter((plan) => plan[0])
                  .map((plan, index) => (
                    <li key={index}>
                      <button
                        onClick={async () => handleSelectPlan(plan)}
                        className={selectedPlan[2] === plan[2] ? "active" : ""}
                      >
                        $
                        {web3Instance.utils.fromWei(
                          plan[2].toString(),
                          "ether"
                        ) +
                          " " +
                          tokenSymbol}
                      </button>
                    </li>
                  ))}
              </ul>
            </div>
            {selectedPlan[1] < 0 ? (
              <div></div>
            ) : Number(web3Instance.utils.fromWei(allowance, "ether")) <
              Number(web3Instance.utils.fromWei(selectedPlan[2], "ether")) +
                Number(web3Instance.utils.fromWei(selectedPlan[3], "ether")) ? (
              <div className="usdt-allowance">
                <p className="token-error" style={{ color: "#ffcc00" }}>
                  * {web3Instance.utils.fromWei(selectedPlan[3], "ether")}{" "}
                  {tokenSymbol} has been added as a convenience fee
                </p>
                {walletSelect ? (
                  ""
                ) : (
                  <>
                    <p className="wallet-select-error">
                      Please select the wallet to complete transaction.
                    </p>
                  </>
                )}
                <div className="upgrade-btn pb-3">
                  {currentDate < targetDate && (
                    <div className="nakaw-button">
                      <button
                        className={activeButton === 1 ? "active" : ""}
                        onClick={() => {
                          setWalletUpgrade(true);
                          setWalletSelect(true);
                          handleClick(1);
                        }}
                      >
                        Naka Wallet
                      </button>
                      <p>
                        {Number(
                          web3Instance.utils.fromWei(
                            availableNakaToken,
                            "ether"
                          )
                        ).toFixed(4)}{" "}
                        USDT
                      </p>
                      {/* Display the countdown */}
                      
                    </div>
                  )}
                  {/* <div className="nakaw-button">
                    <button
                      className={activeButton === 1 ? "active" : ""}
                      onClick={() => {
                        setWalletUpgrade(true);
                        setWalletSelect(true);
                        handleClick(1);
                      }}
                    >
                      Naka Wallet
                    </button>
                    <p>
                      {Number(
                        web3Instance.utils.fromWei(availableNakaToken, "ether")
                      ).toFixed(4)}{" "}
                      USDT
                    </p>
                  </div> */}
                  <div className="nakaw-button">
                    <button
                      className={activeButton === 2 ? "active" : ""}
                      onClick={() => {
                        setWalletUpgrade(false);
                        setWalletSelect(true);
                        handleClick(2);
                      }}
                    >
                      Web3 Wallet
                    </button>
                    <p>
                      {Number(balance / Math.pow(10, decimals)).toFixed(4)} USDT
                    </p>
                  </div>
                </div>
                <div className="schedule">
                        {timeLeft && (
                          <p>Naka Wallet available for: {timeLeft}</p>
                        )}
                      </div>
                <button
                  disabled={walletSelect ? "" : "disabled"}
                  className="plan-continue-btn"
                  onClick={async () =>
                    handleRetopUpAndUpgrade(selectedPlan[1], walletUpgrade)
                  }
                >
                  Allow{" "}
                  {Number(
                    web3Instance.utils.fromWei(selectedPlan[2], "ether")
                  ) +
                    Number(
                      web3Instance.utils.fromWei(selectedPlan[3], "ether")
                    )}{" "}
                  {tokenSymbol}
                </button>
              </div>
            ) : (
              <div className="plan-continue-btn"></div>
            )}
          </Modal.Body>
        </div>
      </Modal>
      <Modal
        show={showTopUpModal2}
        onHide={handleCloseTopUpModal2}
        backdrop="static"
      >
        <div className="select-plan-rtfy">
          <Modal.Header closeButton>
            <Modal.Title>Select a wallet for transaction</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="re-top-up-plan-btn">
              <button
                onClick={() =>
                  handleRetopUpAndUpgrade(
                    allPackageList[allPackageList.length - 1].packageIndex,
                    true
                  )
                }
              >
                Naka wallet
              </button>
              <button
                onClick={() =>
                  handleRetopUpAndUpgrade(
                    allPackageList[allPackageList.length - 1].packageIndex,
                    false
                  )
                }
              >
                Your wallet
              </button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      <Modal
        show={showTopUpModal3}
        onHide={handleCloseTopUpModal3}
        backdrop="static"
      >
        <div className="withdraw">
          <Modal.Header closeButton>
            <Modal.Title>Top-Up History</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table>
              <thead>
                <tr>
                  <th scope="col">S.No.</th>
                  <th scope="col">Status</th>
                  <th scope="col">Price</th>
                  <th scope="col">Time</th>
                </tr>
              </thead>
              <tbody>
                {allPackageList && allPackageList.length > 0 ? (
                  <>
                    {allPackageList
                      .slice()
                      .reverse()
                      .map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}.</td>
                            <td>
                              {item.isActive === true ? "Active" : "Inactive"}
                            </td>
                            <td>
                              {getPriceByIndex(item.packageIndex)}{" "}
                              {packageJoiningTokenSymbol}
                            </td>
                            <td>
                              {convertTimestampToDateTime(item.timestamp)}
                            </td>
                          </tr>
                        );
                      })}
                  </>
                ) : (
                  <div>
                    <span>No Top-Up history...</span>
                  </div>
                )}
              </tbody>
            </table>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default injectModels(["admin", "application"])(Dashboard);
