import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { createTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";

import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Web3 from "web3";
import { useAccount } from "wagmi";
import * as CONTRACTS from "../../Contract";
import { injectModels } from "../../Redux/injectModels";
import TableNewBody from "./TableBody";

const NonWoring = (props) => {
  const [walletAddress, setWalletAddress] = useState("");
  const [value, setValue] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [web3Instance, setweb3Instance] = useState();
  const [packages, setPackages] = useState([]);
  const [prices, setPrices] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedWallet, setSelectedWallet] = useState("");
  const [allpackage, setAllpackage] = useState("");

  const ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;
  const BLOCKCHAIN_NETWORK = process.env.REACT_APP_BLOCKCHAIN_NETWORK;
  const { address, isConnecting, isDisconnected } = useAccount({
    onConnect({ address, connector, isReconnected }) {
      setWalletAddress(address);
    },
    onDisconnect() {
      window.location.reload();
    },
  });

  useEffect(() => {
    fetchPackageDetails();
  }, []);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#12b6b3",
      },
      text: {
        primary: "#12b6b3",
      },
    },
  });

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(4),
    },
    "& .MuiInputBase-input": {
      borderRadius: "6px",
      position: "relative",
      backgroundColor: "var(--card-bg)",
      border: "solid 2px rgba(18, 182, 179, 0.4)",
      color: "#fff",
      align: "centre",
      fonttime: "10px",
      padding: "10px 26px 10px 10px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: "poppins",
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }));

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
        style={{ width: "100%", height: 300 }}
      >
        {value === index && (
          <Box sx={{ p: 3, width: "100%" }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const columns = [
    { id: "name", label: "S.No", minWidth: 170, align: "centre" },
    { id: "amount", label: "Amount", minWidth: 170, align: "centre" },
    { id: "date", label: "Date", minWidth: 170, align: "centre" },
    { id: "time", label: "Time", minWidth: 170, align: "centre" },
    { id: "wallet", label: "Wallet", minWidth: 170, align: "centre" },
  ];

  function createData(name, amount, date, time, wallet) {
    return { name, amount, date, time, wallet };
  }

  const rows = [
    createData("1", "0.375", "2024-03-05", "5:30 am", "Spot wallet"),
    createData("2", "0.375", "2024-03-06", "5:30 am", "Spot wallet"),
    createData("3", "0.375", "2024-03-07", "5:30 am", "Naka wallet"),
    createData("4", "0.375", "2024-03-08", "5:30 am", "Spot wallet"),
    createData("5", "0.375", "2024-03-09", "5:30 am", "Naka wallet"),
    createData("6", "0.375", "2024-03-05", "5:30 am", "Spot wallet"),
    createData("7", "0.375", "2024-03-06", "5:30 am", "Spot wallet"),
    createData("8", "0.375", "2024-03-07", "5:30 am", "Naka wallet"),
    createData("9", "0.375", "2024-03-08", "5:30 am", "Spot wallet"),
    createData("10", "0.375", "2024-03-09", "5:30 am", "Naka wallet"),
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  const getPackageJoiningDetails = async () => {
    if (address) {
      props.application.setLoading(true);
      try {
        const provider = window.ethereum
          ? window.ethereum
          : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);

        const web3 = new Web3(provider);
        setweb3Instance(web3);
        const contract = new web3.eth.Contract(CONTRACTS.Nakamoto.abi, ADDRESS);

        const packageJoiningDetails = await contract.methods
          .getPackagesWithJoiningDetails(address)
          .call();

        const userDetails = await contract.methods.getUser(address).call();
        // setPackagePrice(packageJoiningDetails.packages_);
        setPackages(userDetails.joinedPackages);
        props.application.setLoading(false);
        return packageJoiningDetails;
      } catch (err) {
        console.log(err, "err");
        props.application.setLoading(false);
      }
    }
  };
  const fetchPackageDetails = async () => {
    try {
      const pd = await getPackageJoiningDetails();
      const newPrices = pd[0].map((e) => ({
        packageIndex: e.packageIndex,
        price: e.price,
      }));
      // setAllpackage(pd);
      setPrices(newPrices);
    } catch (error) {
      console.error("Error fetching package details:", error);
    }
  };

  const getPriceByIndex = (index) => {
    const priceObject = prices.find((p) => p.packageIndex === index);
    return priceObject
      ? web3Instance.utils.fromWei(priceObject.price, "ether")
      : 0;
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const filterByWallet = (row) => {
    if (selectedWallet === "") return true;
    return row.wallet === selectedWallet;
  };

  const filterByDateRange = (row) => {
    if (!startDate || !endDate) return true;
    const rowDate = new Date(row.date);
    return rowDate >= startDate && rowDate <= endDate;
  };

  const filteredRows = rows.filter(
    (row) => filterByDateRange(row) && filterByWallet(row)
  );

  return (
    <div
      className="tab-pane fade show active accordion-item"
      id="non-working-tab-pane"
      role="tabpanel"
      aria-labelledby="non-working-tab"
      tabIndex={0}
    >
      <div id="chart-container"></div>
      <div
        id="collapseOne"
        className="accordion-collapse collapse show  d-lg-block"
        aria-labelledby="headingOne"
        data-bs-parent="#myTabContent"
      >
        <div className="accordion-body">
          <div className="row g-2 mb-2 justify-content-center">
            <div className="income-table">
              <div className="nonworking">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker", "DatePicker"]}>
                    <DemoItem label="Start date" sx={{ color: "#fff" }}>
                      <DatePicker
                        sx={{
                          width: "100%",
                          bgcolor: "var(--card-bg)",
                          border: "solid 2px rgba(18, 182, 179, 0.4)",
                          borderRadius: "6px",
                          color: "white",
                        }}
                        slotProps={{
                          actionBar: { actions: ["clear"] },
                        }}
                        selected={startDate}
                        onChange={handleStartDateChange}
                      />
                    </DemoItem>
                    <DemoItem label="End date">
                      <DatePicker
                        sx={{
                          width: "100%",
                          bgcolor: "var(--card-bg)",
                          border: "solid 2px rgba(18, 182, 179, 0.4)",
                          borderRadius: "6px",
                          color: "white",
                        }}
                        slotProps={{
                          actionBar: { actions: ["clear"] },
                        }}
                        selected={endDate}
                        onChange={handleEndDateChange}
                      />
                    </DemoItem>
                    <DemoItem label="Wallet" sx={{ color: "#fff" }}>
                      <FormControl
                        sx={{ m: 1, minWidth: 120 }}
                        variant="standard"
                      >
                        <Select
                          labelId="demo-customized-select-label"
                          id="demo-customized-select"
                          value={selectedWallet}
                          onChange={(event) =>
                            setSelectedWallet(event.target.value)
                          }
                          input={<BootstrapInput />}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value="">All wallet</MenuItem>
                          <MenuItem value="Spot wallet">Spot wallet</MenuItem>
                          <MenuItem value="Naka wallet">Naka wallet</MenuItem>
                        </Select>
                      </FormControl>
                    </DemoItem>
                  </DemoContainer>
                </LocalizationProvider>
              </div>

              <Box
                sx={{
                  flexGrow: 1,
                  bgcolor: "var(--card-bg)",
                  border: "solid 1px rgba(18, 182, 179, 0.4)",
                  borderRadius: "5px",
                  color: "white",
                  display: "flex",
                  height: 650,
                  width: "100%",
                }}
              >
                <div className="pack-list">
                  <Tabs
                    orientation="vertical"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{
                      borderRight: 1,
                      borderColor: "divider",
                      paddingTop: "25px",
                      color: "white",

                      "& .MuiTabs-indicator": {
                        backgroundColor: theme.palette.primary.main,
                      },
                      "& .MuiTab-root": {
                        color: "white",
                      },
                      "& .Mui-selected": {
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: "5px",
                        color: "white",
                        marginLeft: "10px",
                        marginRight: "15px",
                      },
                    }}
                  >
                    {packages
                      .slice()
                      .reverse()
                      .map((item, index) => {
                        return (
                          <Tab
                            sx={{ color: "white" }}
                            label={
                              getPriceByIndex(item.packageIndex) + " " + "$"
                            }
                            {...a11yProps(index)}
                          />
                        );
                      })}
                  </Tabs>
                </div>
                {packages
                  .slice()
                  .reverse()
                  .map((item, index) => (
                    <TabPanel value={value} index={index} key={index}>
                      <TableNewBody
                        item={item}
                        columns={columns}
                        allpackages={prices}
                        startDate={startDate}
                        endDate={endDate}
                        selectedWallet={selectedWallet}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                    </TabPanel>
                  ))}
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectModels(["admin", "application"])(NonWoring);
