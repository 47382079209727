
import React, { useState, useEffect } from "react";
import Counter from "./Counter";



const RecentActivity = () => {
  //Time-stamp of launch
  const startTimeStamp=1712341800;

  const totalUsersAddedInOneDay=24*12; //Users Added in One Day
  const totalProfitGenratedInOneDay=24*12*40; //Profit Added in One Day 

  const lastUsers=502042;
  const lastProfit=27635780;

  const ONEDAY=24*60*60;

  const rateOfUsersGrowth=totalUsersAddedInOneDay/ONEDAY; //80 users per 24 hours
  const rateOfProfitGrowth=totalProfitGenratedInOneDay/ONEDAY; //8000 profit per 24 hours

  const [count, setCount] = useState(() => { 
    const currentTime=Math.floor(Date.now() / 1000);
    const extraTime=currentTime-startTimeStamp;
    const extraUsers=Math.floor(extraTime*rateOfUsersGrowth);
    const todaysExtraTime=extraTime-Math.floor(extraTime/ONEDAY)*(ONEDAY);
    const todaysUsers=Math.floor((todaysExtraTime)*rateOfUsersGrowth);
    const totalUsers=lastUsers+extraUsers;
    return {totalUsers:totalUsers,todaysUsers:todaysUsers};
  });
  const [totalProfit, setTotalProfit] = useState(() => {
    const currentTime=Math.floor(Date.now() / 1000);
    const extraTime=currentTime-startTimeStamp;
    const extraProfit=Math.floor(extraTime*rateOfProfitGrowth);
    const todaysExtraTime=extraTime-Math.floor(extraTime/ONEDAY)*(ONEDAY);
    const todaysProfit=Math.floor((todaysExtraTime)*rateOfProfitGrowth);
    const totalProfit=lastProfit+extraProfit;
    return {totalProfit:totalProfit,todaysProfit:todaysProfit};
  });

  useEffect(() => {
    const intervalId = setInterval(() => { 
      setCount(() => {
        const currentTime=Math.floor(Date.now() / 1000);

        const extraTime=currentTime-startTimeStamp;
        const extraUsers=Math.floor(extraTime*rateOfUsersGrowth);
        const todaysExtraTime=extraTime-Math.floor(extraTime/ONEDAY)*(ONEDAY);
        const todaysUsers=Math.floor((todaysExtraTime)*rateOfUsersGrowth);
        const totalUsers=lastUsers+extraUsers;
        return {totalUsers:totalUsers,todaysUsers:todaysUsers};
      });
      setTotalProfit(() => {
        const currentTime=Math.floor(Date.now() / 1000);
        const extraTime=currentTime-startTimeStamp;
        const extraProfit=Math.floor(extraTime*rateOfProfitGrowth);
        const todaysExtraTime=extraTime-Math.floor(extraTime/ONEDAY)*(ONEDAY);
        const todaysProfit=Math.floor((todaysExtraTime)*rateOfProfitGrowth);
        const totalProfit=lastProfit+extraProfit;
        return {totalProfit:totalProfit,todaysProfit:todaysProfit};
      });
    }, 1000*60*5);
    return () => clearInterval(intervalId);
  }, []);
  return (
    <>
      <section className="recent-activity">
        <div className="bg-heading">
          <h2>
            <span> Platform Recent Activity </span>
          </h2>
          <p>Real time global events of Naka Token platform</p>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="joining-total">
                <h3>Total Joining in Last 24 hours</h3>
                
                  <h2>
                  <span>
                  +<Counter number={count.todaysUsers} />
                </span>
                
                  <div className="counter-update">
                  {count.totalUsers}
                  </div>
                </h2>
              </div>
            </div>
            <div className="col-md-6">
              <div className="joining-total">
                <h3>Total Profit in Last 24 hours</h3>
                 <h2>
                 <span>+<Counter number={totalProfit.todaysProfit} />
                 </span>
                  <div className="counter-update">  
                    {totalProfit.totalProfit}
  
                  </div>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RecentActivity;

