import * as CONSTANTS from "./constants";

const initialState = {
  totalJoin: 0,
  profitGenerated: 0,
  notificationCount: 0,
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_TOTAL_JOIN":
      return {
        ...state,
        totalJoin: action.payload,
      };
    case "UPDATE_PROFIT_GENERATED":
      return {
        ...state,
        profitGenerated: action.payload,
      };
    case CONSTANTS.FETCH_NOTIFICATION_COUNT:
      return {
        ...state,
        notificationCount: action.payload.data.notificationCount,
      };
    default:
      return state;
  }
};

export default adminReducer;
