import React from "react";
import NonWoring from "./NonWoring";
import Sponsor from "./Sponsor";
import TeamBuildBonus from "./TeamBuildBonus";
import Boost from "./Boost";
import Royalt from "./Royalt";

const Report = () => {
  return (
    <section className="network report-new">
      <div className="container-fluid">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="non-working-tab"
              data-bs-toggle="tab"
              data-bs-target="#non-working-tab-pane"
              aria-selected="true"
              type="button"
              role="tab"
              aria-controls="non-working-tab-pane"
            >
              Non-working
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link "
              id="sponsor-tab"
              data-bs-toggle="tab"
              data-bs-target="#sponsor-tab-pane"
              type="button"
              role="tab"
              aria-controls="sponsor-tab-pane"
              aria-selected="false"
            >
              sponsor
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="teamBuildBonus-tab"
              data-bs-toggle="tab"
              data-bs-target="#teamBuildBonus-tab-pane"
              type="button"
              role="tab"
              aria-controls="teamBuildBonus-tab-pane"
              aria-selected="false"
            >
              Team Build Bonus
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="boost-tab"
              data-bs-toggle="tab"
              data-bs-target="#boost-tab-pane"
              type="button"
              role="tab"
              aria-controls="boost-tab-pane"
              aria-selected="false"
            >
              Boost
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="royalty-tab"
              data-bs-toggle="tab"
              data-bs-target="#royalty-tab-pane"
              type="button"
              role="tab"
              aria-controls="royalty-tab-pane"
              aria-selected="false"
            >
              Royalty
            </button>
          </li>
        </ul>
        <div className="tab-content accordion" id="myTabContent">
        <NonWoring />
        <Sponsor/>
        <TeamBuildBonus/>
        <Boost/>
        <Royalt/>
        </div>
      </div>
    </section>
  );
};

export default Report;
