import React, { useState, useEffect , useRef } from "react";
// import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import OwlCarousel from "react-owl-carousel";
import pdfFile from './nakatoken-school.pdf'

const NakamotoSchool = () => {

  const owlCarouselRef = useRef(null);

  const handlePrev = () => {
    if (owlCarouselRef.current) {
      owlCarouselRef.current.prev();
    }
  };

  const handleNext = () => {
    if (owlCarouselRef.current) {
      owlCarouselRef.current.next();
    }
  };

  const handleDownloadPDF = () => {
    const link = document.createElement("a");
    link.href = pdfFile;
    link.download = "nakatoken_school.pdf"; 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  return (
    <section className="terms-condtion">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="terms-content">
              <h2>
                {" "}
                <span> Naka Token School </span>
              </h2>
              <div>
                {/* <DocViewer
              documents={[{ uri: uri }]}
              initialActiveDocument={{ uri: uri }}
              pluginRenderers={DocViewerRenderers}
              style={{ height: '700px', width: '100%', marginBottom:"30px" ,marginTop:"0px"}} 
              config={{
                header: {
                  disableHeader: true 
                }
              }}
            /> */}
                <div className="pdfslider">
                  <OwlCarousel
                   ref={owlCarouselRef}
                    className="owl-carousel owl-theme"
                    loop={false}
                    margin={10}
                    nav={false}
                    responsive={{
                      0: { items: 1 },
                      600: { items: 1 },
                      1000: { items: 1 },
                    }}
                  >

                    <div className="item">
                    {" "}
                    <div className="pdf-image">
                      <img src="assets/img/pdf1.jpg" />
                    </div>{" "}
                  </div>
                    <div className="item">
                      {" "}
                      <div className="pdf-image">
                        <img src="assets/img/pdf2.jpg" />
                      </div>{" "}
                    </div>
                    <div className="item">
                      {" "}
                      <div className="pdf-image">
                        <img src="assets/img/pdf3.jpg" />
                      </div>{" "}
                    </div>
                    <div className="item">
                      {" "}
                      <div className="pdf-image">
                        <img src="assets/img/pdf4.jpg" />
                      </div>{" "}
                    </div>
                    <div className="item">
                      {" "}
                      <div className="pdf-image">
                        <img src="assets/img/pdf5.jpg" />
                      </div>{" "}
                    </div>
                    <div className="item">
                      {" "}
                      <div className="pdf-image">
                        <img src="assets/img/pdf6.jpg" />
                      </div>{" "}
                    </div>
                    <div className="item">
                      {" "}
                      <div className="pdf-image">
                        <img src="assets/img/pdf7.jpg" />
                      </div>{" "}
                    </div>
                    <div className="item">
                      {" "}
                      <div className="pdf-image">
                        <img src="assets/img/pdf8.jpg" />
                      </div>{" "}
                    </div>
                    <div className="item">
                      {" "}
                      <div className="pdf-image">
                        <img src="assets/img/pdf9.jpg" />
                      </div>{" "}
                    </div>
                    <div className="item">
                      {" "}
                      <div className="pdf-image">
                        <img src="assets/img/pdf10.jpg" />
                      </div>{" "}
                    </div>
                    <div className="item">
                      {" "}
                      <div className="pdf-image">
                        <img src="assets/img/pdf11.jpg" />
                      </div>{" "}
                    </div>

                    <div className="item">
                      {" "}
                      <div className="pdf-image">
                        <img src="assets/img/pdf12.jpg" />
                      </div>{" "}
                    </div>
                    <div className="item">
                      {" "}
                      <div className="pdf-image">
                        <img src="assets/img/pdf13.jpg" />
                      </div>{" "}
                    </div>
                    <div className="item">
                      {" "}
                      <div className="pdf-image">
                        <img src="assets/img/pdf14.jpg" />
                      </div>{" "}
                    </div>
                    <div className="item">
                      {" "}
                      <div className="pdf-image">
                        <img src="assets/img/pdf15.jpg" />
                      </div>{" "}
                    </div>
                    <div className="item">
                      {" "}
                      <div className="pdf-image">
                        <img src="assets/img/pdf16.jpg" />
                      </div>{" "}
                    </div>
                    <div className="item">
                      {" "}
                      <div className="pdf-image">
                        <img src="assets/img/pdf17.jpg" />
                      </div>{" "}
                    </div>
                    <div className="item">
                      {" "}
                      <div className="pdf-image">
                        <img src="assets/img/pdf18.jpg" />
                      </div>{" "}
                    </div>
                  </OwlCarousel>
                  <button className="custom-prev" onClick={handlePrev}>
                  &lt;
                </button>
                <button className="custom-next" onClick={handleNext}>
                  &gt;
                </button>
                <button className="dnbtn" onClick={handleDownloadPDF}><i class="fa fa-download"></i></button>
                </div>
                {/* <div className="downloadpdf">
               
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NakamotoSchool;


// import React, { useState, useEffect, useRef } from "react";
// import OwlCarousel from "react-owl-carousel";
// import pdfFile from './nakatoken-school.pdf';

// const images = [
//   "assets/img/pdf1.jpg",
//   "assets/img/pdf2.jpg",
//   "assets/img/pdf3.jpg",
//   "assets/img/pdf4.jpg",
//   "assets/img/pdf5.jpg",
//   "assets/img/pdf6.jpg",
//   "assets/img/pdf7.jpg",
//   "assets/img/pdf8.jpg",
//   "assets/img/pdf9.jpg",
//   "assets/img/pdf10.jpg",
//   "assets/img/pdf11.jpg",
//   "assets/img/pdf12.jpg",
//   "assets/img/pdf13.jpg",
//   "assets/img/pdf14.jpg",
//   "assets/img/pdf15.jpg",
//   "assets/img/pdf16.jpg",
//   "assets/img/pdf17.jpg",
//   "assets/img/pdf18.jpg"
// ];

// const NakamotoSchool = () => {
//   const owlCarouselRef = useRef(null);

//   const handlePrev = () => {
//     if (owlCarouselRef.current) {
//       owlCarouselRef.current.prev();
//     }
//   };

//   const handleNext = () => {
//     if (owlCarouselRef.current) {
//       owlCarouselRef.current.next();
//     }
//   };

//   const handleDownloadPDF = () => {
//     const link = document.createElement("a");
//     link.href = pdfFile;
//     link.download = "nakatoken_schoool.pdf"; 
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//   };

//   return (
//     <section className="terms-condtion">
//       <div className="container">
//         <div className="row">
//           <div className="col-md-12">
//             <div className="terms-content">
//               <h2>
//                 <span> Naka Token School </span>
//               </h2>
//               <div className="pdfslider">
//                 <OwlCarousel
//                   ref={owlCarouselRef}
//                   className="owl-carousel owl-theme"
//                   loop={false}
//                   margin={10}
//                   nav={false}
//                   responsive={{
//                     0: { items: 1 },
//                     600: { items: 1 },
//                     1000: { items: 1 },
//                   }}
//                 >
//                   {images.map((src, index) => (
//                     <div className="item" key={index}>
//                       <div className="pdf-image">
//                         <img src={src} loading="lazy" alt={`Page ${index + 1}`} />
//                       </div>
//                     </div>
//                   ))}
//                 </OwlCarousel>
//                 <button className="custom-prev" onClick={handlePrev}>
//                   &lt;
//                 </button>
//                 <button className="custom-next" onClick={handleNext}>
//                   &gt;
//                 </button>
//                 <button className="dnbtn" onClick={handleDownloadPDF}><i className="fa fa-download"></i></button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default NakamotoSchool;
