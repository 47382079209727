import React, { useEffect, useState } from "react";

const Roadmap = () => {
  const [language, setLanguage] = useState("en");

  useEffect(() => {
    setInterval(function () {
      const lang = readCookie("googtrans");
      if (lang !== undefined && lang !== null) {
        setLanguage(lang.split("/")[2]);
      }
    }, 1000);
  }, [useState]);
  function readCookie(name) {
    var c = document.cookie.split("; "),
      cookies = {},
      i,
      C;

    for (i = c.length - 1; i >= 0; i--) {
      C = c[i].split("=");
      cookies[C[0]] = C[1];
    }

    return cookies[name];
  }
  return (
    <>
      <section className="roadmap-section">
        <div className="bg-heading-roadmap">
          <h2>
            <span> Roadmap </span>
          </h2>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="roadmap-img for-desktop">
                {language === "en" ? (
                  <img
                    src="/assets/img/Group 99.svg"
                    className="img-fluid"
                    alt="true"
                  />
                ) : (
                  <img
                    src="/assets/img/jap.svg"
                    className="img-fluid"
                    alt="true"
                  />
                )}
              </div>

              <div className="roadmap-img for-mobile">
                {language === "en" ? (
                  <img
                    src="/assets/img/roadmap-english-mobile.svg"
                    className="img-fluid"
                    alt="true"
                  />
                ) : (
                  <img
                    src="/assets/img/roadmap-japanese-mobile.svg"
                    className="img-fluid"
                    alt="true"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="nft shadow-box">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="nft-img">
                <img src="/assets/img/nft.png" className="img-fluid" alt="" />
              </div>
              <div className="nft-content">
                <p>
                  Explore an exciting journey through the world of NFTs,
                  Metaverse, and Gaming. Explore the limitless possibilities of
                  decentralized finance as we revolutionize the way you interact
                  with digital assets. Our roadmap is packed with innovation and
                  adventure, ensuring that you stay at the forefront of the
                  crypto revolution. Get ready to dive into a whole new
                  dimension of financial freedom and digital creativity.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Roadmap;
