import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import IconTint from "react-icon-tint";
const Footer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const currentYear = new Date().getFullYear();

  const toggleButton = () => {
    setIsOpen(!isOpen);
  };

  return (
    <React.Fragment>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="footer-menu">
                <Link
                  to={{
                    pathname:
                      "https://www.quillaudits.com/leaderboard/naka-token",
                  }}
                  target="_blank"
                >
                  <IconTint
                    style={{
                      height: "30px",
                      margin: "0 auto",
                      marginBottom: "12px",
                      display: "flex",
                      textAlign: "center",
                    }}
                    src="/assets/img/WAGSI Banner.png"
                    color="transparent"
                  />
                </Link>
                <ul>
                  <li>
                    <Link to="/about-us"> About Us</Link>
                  </li>
                  <li>
                    <Link
                      to={{
                        pathname: "https://nakatoken.io/blog/",
                      }}
                      target="_blank"
                    >
                      Blogs
                    </Link>
                  </li>
                  <li>
                    <Link to="/nakamoto-school"> Naka Token School</Link>
                  </li>

                  <li>
                    <Link to="/terms-and-condition">
                      {" "}
                      Terms &amp; Conditions
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy"> Privacy &amp; Policy</Link>
                  </li>
                  <li>
                    <Link to="/contact"> Contact Us</Link>
                  </li>
                </ul>

                <p>All Rights Reserved. © Naka Token {currentYear}</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
